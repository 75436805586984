import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CurrencySelectActions, selectCurrenciesList, selectCurrenciesLoading } from './currency-select.store';
import { ResourcesService } from '@app/modules/core/services/resources.service';
import { Store } from '@ngrx/store';

@Injectable()
export class CurrencySelectEffects {
    constructor(
        private actions$: Actions,
        private resourcesService: ResourcesService,
        private store: Store,
    ) {}

    load$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CurrencySelectActions.load),
            withLatestFrom(
                this.store.select(selectCurrenciesList),
                this.store.select(selectCurrenciesLoading),
            ),
            filter(([,,loading]) => {
                return !loading;
            }),
            switchMap(([,list]) => {
                if (list?.length) {
                    return;
                }

                this.store.dispatch(CurrencySelectActions.loadStarted());

                return this.resourcesService.getCurrencies().pipe(
                    map((res: any) => {
                        return CurrencySelectActions.loadSuccess({ currencies: res });
                    }),
                    catchError((error) =>
                        of(CurrencySelectActions.loadFailure({ error }))
                    ),
                );
            }),
        );
    });
}
