import { createReducer, on, createAction, props, createFeatureSelector, createSelector } from '@ngrx/store';
import { STRIPE_SUPPORTED_CURRENCY } from '@app/types/general';

export interface CurrencySelectState {
    list: STRIPE_SUPPORTED_CURRENCY[];
    error: null | Error;
    loading: boolean;
}

export const initialState: CurrencySelectState = {
    list: [],
    error: null,
    loading: false,
};

export const selectCurrenciesSelectState = createFeatureSelector<CurrencySelectState>(
    'currencySelect',
);

export const selectCurrenciesList = createSelector(
    selectCurrenciesSelectState,
    (s) => s.list,
);
export const selectCurrenciesLoading = createSelector(
    selectCurrenciesSelectState,
    (s) => s.loading,
);

export const CurrencySelectActions = {
    load: createAction(
        '[CurrenciesSelect] Load',
    ),

    loadStarted: createAction(
        '[CurrenciesSelect] Load Started',
    ),

    loadFailure: createAction(
        '[CurrenciesSelect] Load Failure',
        props<{ error: any }>(),
    ),

    loadSuccess: createAction(
        '[CurrenciesSelect] Load Success',
        props<{ currencies: any }>(),
    ),
};

export const currenciesReducer = createReducer(
    initialState,

    on(CurrencySelectActions.loadStarted, (state) => ({
        ...state,
        loading: true,
    })),
    on(CurrencySelectActions.loadSuccess, (state, { currencies }) => {
        return {
            ...state,
            list: [...currencies],
            byId: currencies.reduce((acc, r) => {
                acc[r.id] = r;
                return acc;
            }, {}),
            loading: false,
        };
    }),
    on(CurrencySelectActions.loadFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),
);
