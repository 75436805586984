import { UserDetails } from '@app/modules/shared/models/user-details';
import {
    AutoTopupSettings,
    BGSKU,
    BGStock,
    BillingModel,
    COUNTRY,
    Paginated,
    Statuses,
} from './general';
import { ResellerMinifiedInterface } from './resellers';

export interface BillingGroupAccessFee {
    amount: number;
    enabled: boolean;
}

export enum BillingGroupFrequencies {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    YEARLY = 'yearly',
    MONTHLY = 'monthly',
    QUARTERLY = 'quarterly',
    HALF_YEARLY = 'half-yearly',
}

export enum BillingGroupModels {
    DATAONSIM = 'dtonsim',
    DATAONPOOL = 'dtonpool',
    CORPORATE = 'corporate',
}

export enum BillingGroupStatuses {
    ACTIVE = 'active',
    REQUEST = 'request',
    INACTIVE = 'inactive',
    REJECTED = 'rejected',
    TERMINATED = 'terminated',
}

export enum BillingGroupChargeUpon {
    APPROVAL = 'approval',
    CONNECTION = 'connection',
    SUBSCRIPTION = 'subscription',
}

export enum BillingGroupPricingModel {
    SUBSCRIPTION = 'subscription',
    TRANSACTIONAL = 'transactional',
}

export enum BillingGroupChargingModel {
    LOAD_BASED = 'load-based',
    USAGE_BASED = 'usage-based',
}

export enum BillingGroupBundleType {
    MERCHANT = 'merchant',
    RESELLER = 'reseller',
}

export enum BillingGroupDataPlanUsageType {
    CAPPED = 'capped',
    UNLIMITED = 'unlimited',
}

export interface PreparedRateSheetCountryInterface extends COUNTRY {
    name: string;
    all_operatorcodes: string[];
    operators: PreparedRateOperatorsInterface[];
}

export enum NetworkOperatorTags {
    CHILD = 'Child',
    PARENT = 'Parent',
}

export interface PreparedRateOperatorsInterface {
    apn: string[];
    price: number;
    mccmnc: string;
    country: string;
    operator: string;
    operatorcode: string;
    core_status: Statuses;
    tag: NetworkOperatorTags;
    children_operators: PreparedRateOperatorsInterface[];
}

export interface BillingGroupCoverageGroupInterface {
    id: string;
    title: string;
    countries: PreparedRateSheetCountryInterface[];
}

export interface BillingGroup {
    id: string;
    price: number;
    title: string;
    status: BillingGroupStatuses;
    action_required: '' | 'approval' | 'payment' | 'subscription';
    coverage_group_id: string;
    retail_billing_group_id: string;
    remarks: string[];
    mcc_or_area_code?: string;
    settings: {
        access_fee: BillingGroupAccessFee;
        apn: string;
        apn_hidden: boolean;
        prorate: boolean;
        proratable: boolean;
        autorenew: boolean;
        data_unit: 1000;
        data_unit_per: 'MB';
        frequency_unit: number;
        load_value: number;
        load_when_value: number;
        billing_model_id: BillingGroupModels;
        frequency: BillingGroupFrequencies;
        charge_upon: BillingGroupChargeUpon;
        balances: {
            main_data: {
                plan: number;
                pool_balance: number;
            };
            throttle_data: {
                plan: number;
                pool_balance: number;
            };
        };
        pricing_model: BillingGroupPricingModel;
        charging_model: BillingGroupChargingModel;
        data_plan: BillingGroupDataPlanUsageType;

        /**
         * Fields for handling `transactional` type (Plans)
         */
        time_for_activation?: number;
        validity_in_days?: number;

        /**
         * Indicates that billing group was created by reseller on his own taking
         * base from ratesheet.
         */
        self_managed?: boolean;

        webbing_product_id?: string;
    };
    user_id: string;
    currency: string;
    created_by: string;
    reseller_id: string;
    creation_date: string;
    next_bill_date: string;
    subscriptions: {
        total: number;
        billed: number;
    };
    billing_model: BillingModel;
    subscribed_skus?: Paginated<BGStock> | Paginated<BGSKU>;
    coverage_group_details?: BillingGroupCoverageGroupInterface;
    auto_topup_product_id: string;
    auto_topup_amount?: number;
    auto_topup_settings: AutoTopupSettings;

    client?: UserDetails;
    attached?: BillingGroup[];
    reseller?: ResellerMinifiedInterface;
    bundle_type?: BillingGroupBundleType;
    purpose?: string;
    custom_apn?: string; // custom apn string for customised APN
    client_name?: string;

    ratesheet_id?: number; // track ratesheet assigned to a plan
    plan_code?: string;
    imsi_profile?: string;
    active_plans_count?: number;
}
