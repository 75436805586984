import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemUserTypes } from '@shared/models/enums/system-user-types';
import { MonthsSince } from '@shared/models/months-since';
import {
    BillingGroup,
    BillingGroupAccessFee,
    BillingGroupChargeUpon,
    BillingGroupCoverageGroupInterface,
    BillingGroupFrequencies,
    BillingGroupModels,
    BillingGroupStatuses,
    NetworkOperatorTags,
    PreparedRateOperatorsInterface,
    PreparedRateSheetCountryInterface,
} from '@app/types/billingGroups';
import {
    AutoTopupSettings,
    BGSKU,
    BGStock,
    BillingModel,
    COUNTRY,
    ChipType,
    ClientBillingTypes,
    STRIPE_SUPPORTED_CURRENCY,
    SkuConnectionDetails,
    Statuses,
} from '@app/types/general';
import {
    PostpaidCapSetting,
    ResellerAddressInterface,
    ResellerMinifiedInterface,
} from '@app/types/resellers';

export let APP_CONFIG = new InjectionToken('config');

export const DEFAULT_CURRENCY = 'USD';

export const AppConfig: IAppConfig = {
    apiEndpoints: {
        admin: {
            manage: `${environment.api_endpoint}admin`,
            pic_management: `${environment.api_endpoint}admin/pic`,
            reports: `${environment.api_endpoint}client/{id}/reporting/`,
            payment: `${environment.api_endpoint}reseller/{id}/checkout/`,
        },
        crm: {
            users: `${environment.api_endpoint}crm/users`,
            reports: `${environment.api_endpoint}crm/reports/`,
            finance: `${environment.api_endpoint}crm/finance/`,
            pic_management: `${environment.api_endpoint}crm/pic`,
            resellers: `${environment.api_endpoint}crm/resellers`,
        },
        client: `${environment.api_endpoint}client`,
        finance: {
            orders: `${environment.api_endpoint}finance/orders/`,
            invoices: `${environment.api_endpoint}finance/invoices/`,
            products: `${environment.api_endpoint}finance/products/`,
            requests: `${environment.api_endpoint}finance/requests/`,
            refund: `${environment.api_endpoint}finance/invoices/refund/`,
            billing_groups: `${environment.api_endpoint}finance/billing-groups`,
        },
        operations: {
            loginas: `${environment.api_endpoint}operations/loginas/`,
        },
        cards: `${environment.api_endpoint}client/{id}/cards`,
        alerts: `${environment.api_endpoint}client/{id}/alerts/`,
        reports: {
            generate: `${environment.api_endpoint}client/{id}/reports/`,
            history: `${environment.api_endpoint}client/{id}/reports/histories/`,
        },
        history: `${environment.api_endpoint}client/{id}/history/`,
        payment: `${environment.api_endpoint}client/{id}/payment/`,
        refresh_balance: `${environment.api_endpoint}hooks/balance`,
        invoices: `${environment.api_endpoint}client/{id}/invoices`,
        checkout: `${environment.api_endpoint}client/{id}/checkout/`,
        tagging: `${environment.api_endpoint}client/inventory/tags`,
        resources: {
            common: `${environment.api_endpoint}resources/`,
            crm: `${environment.api_endpoint}crm/resources/`,
            admin: `${environment.api_endpoint}admin/resources/`,
            client: `${environment.api_endpoint}client/{id}/resources/`,
        },
        request: {
            inventory: `${environment.api_endpoint}client/{id}/request/`,
            download_receipt: `${environment.api_endpoint}client/{id}/manage/invoices/orders/receipt`,
            soa_verify_payment: `${environment.api_endpoint}client/{id}/manage/invoices/soa/receipts`,
            send_receipt: `${environment.api_endpoint}client/{id}/manage/invoices/orders/resend-receipt`,
            payment_history: `${environment.api_endpoint}client/{id}/manage/invoices/soa/payment-history`,
            receipt_upload_history: `${environment.api_endpoint}client/{id}/manage/invoices/soa/receipt-upload-history`,
            update_delivery_status: `${environment.api_endpoint}client/{id}/manage/invoices/orders/received`,
        },
        fulfillment: {
            shipping_orders: `${environment.api_endpoint}fulfillment/orders/`,
            shipping_requests: `${environment.api_endpoint}fulfillment/requests/`,
            import_inventory: `${environment.api_endpoint}fulfillment/inventory/import`,
            search_inventory: `${environment.api_endpoint}fulfillment/inventory/search/`,
            shipping_options: `${environment.api_endpoint}fulfillment/shipping-options/`,
            inventory_summary: `${environment.api_endpoint}fulfillment/inventory/summary`,
            request_inventory: `${environment.api_endpoint}fulfillment/inventory/request/`,
        },
        billing_group: `${environment.api_endpoint}client/{id}/billing-groups`,
        auth: {
            signin: `${environment.api_endpoint}auth/sign-in`,
            signup: `${environment.api_endpoint}auth/sign-up`,
            signoff: `${environment.api_endpoint}auth/token`,
            session_data: `${environment.api_endpoint}auth/token`,
            resetpassword: `${environment.api_endpoint}auth/forgot-password`,
        },
    },
};

export enum ResellerTabChildPages {
    USERS = 'users',
    PROFILE = 'profile',
    SETTINGS = 'settings',
    STOCKS = 'stocks',
    PRODUCTS = 'products',
    WALLET = 'wallet',
    REPORTING = 'reporting',
    PRIVILEGES = 'privileges',

    // PRODUCTS_DATAPOOL = "datapool",
    // INVENTORY_LIST = "inventorylist",
    // INVENTORY_REQUEST = "inventoryrequest",

    INVOICE_LIST = 'invoicelist',
    SUBSCRIPTIONS = 'subscriptions',
    ACCOUNT_SETTINGS = 'account-settings',
}

export enum ClientTabRootPages {
    USERS = 'users',
    ORDERS = 'orders',
    INVENTORY = 'sim-list',
    REPORTING = 'reporting',
    SYSTEM_ALERTS = 'system-alerts',
    NOTIFICATIONS = 'notifications',
    SUBSCRIPTIONS = 'subscriptions',
    ACCOUNT_SETTINGS = 'account-settings',
    SMS = 'sms',
    USAGE_RECORDS = 'usage-records',
}

export enum ClientTabChildPages {
    WALLET = 'wallet',
    PROFILE = 'profile',
    PAYMENT = 'payment',
    SETTINGS = 'settings',
    API_KEYS = 'api-keys',
    DOCUMENTS = 'documents',
    PRIVILEGES = 'privileges',
    DATAPOOL = 'datapool',
    POLICY_CONTROL = 'policy-control',

    INVENTORY_LIST = 'inventorylist',
    INVENTORY_REQUEST = 'inventoryrequest',

    INVOICE_LIST = 'invoicelist',
    MONTHLY_STATEMENT = 'monthly-statement',
}
export interface TAB {
    icon: string;
    children: TABCHILD[];
    description: string;
    dataTestSelector?: string;
    page: ClientTabRootPages;
}

export interface TABCHILD {
    icon: string;
    description: string;
    page: ClientTabChildPages | ResellerTabChildPages;
}

export interface IAppConfig {
    apiEndpoints: {
        admin: {
            manage: string;
            reports: string;
            payment: string;
            pic_management: string;
        };
        crm: {
            users: string;
            finance: string;
            resellers: string;
            pic_management: string;
            reports: string;
        };
        cards: string;
        client: string;
        alerts: string;
        tagging: string;
        reports: {
            history: string;
            generate: string;
        };
        history: string;
        payment: string;
        checkout: string;
        invoices: string;
        finance: {
            orders: string;
            refund: string;
            invoices: string;
            products: string;
            requests: string;
            billing_groups: string;
        };
        operations: {
            loginas: string;
        };
        request: {
            inventory: string;
            send_receipt: string;
            payment_history: string;
            download_receipt: string;
            soa_verify_payment: string;
            receipt_upload_history: string;
            update_delivery_status: string;
        };
        fulfillment: {
            shipping_orders: string;
            import_inventory: string;
            search_inventory: string;
            shipping_options: string;
            shipping_requests: string;
            inventory_summary: string;
            request_inventory: string;
        };
        resources: {
            common: string;
            crm: string;
            admin: string;
            client: string;
        };
        billing_group: string;
        refresh_balance: string;
        auth: {
            signin: string;
            signup: string;
            signoff: string;
            resetpassword: string;
            session_data: string;
        };
    };
}
export interface SessionInfo {
    token: string;
    app_busy: boolean;
    last_routes: {
        b4payment: string;
        lastvisited: string;
        tabs: {
            admin: {
                profile: ClientTabChildPages;
                invoice: ClientTabChildPages;
            };
            reseller: {
                root: ResellerTabChildPages;
                profile: ResellerTabChildPages;
                products: ProductTypes;
                users: SystemUserTypes.ADMIN | SystemUserTypes.CLIENT;
            };
            client: {
                root: ClientTabRootPages;
                invoice: ClientTabChildPages;
                profile: ClientTabChildPages;
                inventory: ClientTabChildPages;
            };
            finance: {
                invoices: string;
                requests: string;
                billing_groups: BillingGroupStatuses;
            };
            crm: {
                finance: {
                    billing_groups: BillingGroupStatuses;
                };
                fulfillment: {
                    orders: string;
                };
            };
            fulfillment: {
                orders: string;
            };
        };
    };
    proxy_user: string;
    resources: RESOURCES;
    cache: {
        industries: Industry[];
        client_list: userDetails[];
        products: ProductInterface[];
        last_dashboard_client_id: string;
        imported_inventory_from_core: boolean;
        resellers: ResellerMinifiedInterface[];
        coverage_groups: CoverageGroupInterface[];
        supported_countries: PreparedRateSheetInterface;
        supported_countries_simple: PreparedRateSheetInterface;
    };
    user_details: userDetails;
    reseller_details?: ResellerInterface;
}

/**
 * @deprecated use `UserDetails` instead (shared/models/user-details)
 */
export interface userDetails {
    id: string;
    available_esims_count?: number;
    available_sims_count?: number;
    name: string;
    email: string;
    status: string;
    mobile: string;
    roles: string[];
    country: string;
    address: string;
    company: string;
    timezone: string;
    user_type: SystemUserTypes;
    password?: string;
    department: string;
    documents: UserDetailsDocuments[];
    payment: {
        allow: boolean;
        currency: string;
        cards: CREDITCARD[];
        billing_type: ClientBillingTypes;
        sst_percentage: number;
        postpaid: {
            total: number;
            received: number;
            cap: PostpaidCapSetting;
            allow_soa_download: boolean;
            current_statement_id: string;
            postpaid_customer_account: string;
        };
        wallet: {
            balance: number;
        };
        stripe_customer_id: string;
        methods: {
            wallet: boolean;
            creditcard: boolean;
        };
    };
    inventory: {
        sim: InventoryCount;
        esim: InventoryCount;
        microchip: InventoryCount;
    };
    settings: {
        notifications: {
            mail_payment_receipts: boolean;
            auto_mail_esim_emails: boolean;
            mail_sku_subscription_receipts: boolean;
            monthly_merchant_invoice: boolean;
        };
        emailing: {
            payment: EMAILING;
        };
    };
    user_category: SystemUserTypes.ADMIN | SystemUserTypes.CLIENT | SystemUserTypes.CRM;
    creation_date: string;
    main_client_id?: string;
    inventory_tags?: InventoryTag[];
    password_requires_reset: boolean;
    alerts: Alerts[];
    user_account_role: USER_ROLE_TYPE;
    reseller_id?: string;
    tags?: number[];
}

export interface UserDetailsDocuments {
    id: string;
    key: string;
    size: string;
    title: string;
    file_name: string;
    creation_date: string;
}

export enum USER_ROLE_TYPE {
    OWNER = 'owner',
    ADMIN = 'admin',
    FINANCE = 'finance',
    SIM_MANAGEMENT = 'simmanagement',
    SIM_MANAGEMENT_RO = 'simmanagementro',
}

export interface Alerts {
    id: string;
    type: 'wallet';
    enabled: boolean;
    balance: number;
    suggested: number;
}

export interface InventoryTag {
    id?: string;
    tag: string;
    color: string;
    skus: number[];
    bgcolor: string;
    creation_date: string;
}

export interface InventoryCount {
    purchased: number;
    subscribed: number;
}

export interface MainInventoryTrack {
    sim: MainInventoryCount;
    esim: MainInventoryCount;
    microchip: MainInventoryCount;
}

export interface MainInventoryCount {
    total: number;
    assigned: number;
}

export interface CREDITCARD {
    id: string;
    brand: string;
    last4: string;
    primary: boolean;
    exp_year: number;
    exp_month: number;
    billing_address: CREDITCARD_BILLING_ADDRESS;
}

export interface CREDITCARD_BILLING_ADDRESS {
    zip: string;
    email: string;
    state: string;
    mobile: string;
    country: string;
    address: string;
    editable?: boolean;
    full_name: string;
}

export interface RESOURCES {
    apn?: string[];
    countries: COUNTRY[];
    app_roles: APP_ROLE[];
    billing_models: BillingModel[];
    shipping_methods: SHIPPING_METHOD[];
    currencies: STRIPE_SUPPORTED_CURRENCY[];
}

export interface SessionMetaData {
    proxying?: string;
}

export interface USER_CATEGORY {
    id: string;
    category: string;
    roles: string[];
}

/**
 * @deprecated use `AppRole` instead (shared/models/app-role)
 */
export interface APP_ROLE {
    id: string;
    description: string;
    modules: {
        id: string;
        description: string;
        actions: APP_ROLE_ACTION[];
    }[];
}

export interface APP_ROLE_ACTION {
    action: string;
    description: string;
}

export {
    BillingGroup,
    BillingGroupAccessFee,
    BillingGroupFrequencies,
    BillingGroupModels,
    BillingGroupChargeUpon,
    BillingGroupStatuses,
    BillingModel,
    BillingGroupCoverageGroupInterface,
    PreparedRateSheetCountryInterface,
    PreparedRateOperatorsInterface,
    COUNTRY,
    Statuses,
    NetworkOperatorTags,
    ResellerMinifiedInterface,
    ResellerAddressInterface,
    ClientBillingTypes,
    ChipType,
    AutoTopupSettings,
    BGStock,
    BGSKU,
    PostpaidCapSetting,
};

export interface PreparedRateSheetInterface {
    id: string;
    title: string;
    end_date: string;
    start_date: string;
    creation_date: string;
    countries: PreparedRateSheetCountryInterface[];
}

export interface CoverageGroupInterface {
    id: string;
    apn: string;
    custom_apn?: string;
    title: string;
    created_by: string;
    filter_by: 'default';
    creation_date: string;
    operatorcodes: string[];
    billing_groups?: {
        id: string;
        title: string;
        currency: string;
    }[];
}

export interface ClientInventoryBalanceInterface {
    main_data: number;
    billing_cycle_id: string;
}

export interface ClientInventoryBillingGroupHistory {
    subscribed_on: string;
    unsubscribed_on: string;
    billing_group_id: string;
}

export interface ProductInterface {
    id: string;
    price: number;
    title: string;
    ends_on: string;
    status: Statuses;
    currency: string;
    data_unit: number;
    archived: boolean;
    created_by: string;
    data_unit_per: string;
    creation_date: string;
    product_type: ProductTypes;
    exclusive_to_user_ids: string[];
    transition: ProductTransitionInterface;
}

export interface ResellerProductInterface {
    id: string;
    price: number;
    title: string;
    ends_on: string;
    currency: string;
    status: Statuses;
    archived: boolean;
    data_unit: number;
    created_by: string;
    data_unit_per: string;
    creation_date: string;
    product_type: ProductTypes;
    exclusive_to_reseller_ids: string[];
    transition: ProductTransitionInterface;
}

export enum ProductTypes {
    WALLET = 'wallet',
    DATAPOOL = 'datapool',
}

export interface ProductTransitionInterface {
    id: string;
    price: number;
    data_unit: number;
    starts_on: string;
    created_by: string;
    data_unit_per: string;
}

export interface OrderInterface {
    id: string;
    total: number;
    amount: number;
    discount_total: number;
    quantity: number;
    unit_price: number;
    product_id: string;
    order_type_details: OrderType;
    user_id: string;
    title: string;
    skus: BGSKU[];
    discount: {
        on_invoice: OrderDiscountInterface;
        on_checkout: OrderDiscountInterface;
    };
    description: string;
    discount_code: string;
    order_type: OrderTypes;
    auto_fulfilled: boolean;
    remarks: string[];
    tracking_link: string;
    tracking_code: string;
    delivery_date: string;
    delivery_status: string;
    creation_date: string;
}

export enum OrderTypes {
    PRODUCT = 'product',
    SHIPMENT = 'shipping',
    INVENTORY = 'inventory',
    SIM_ACCESS_FEE = 'sim_access_fee',
    SKU_SUBSCRIPTION = 'sku_subscription',
    BILLING_GROUP_RENEWAL = 'datapool_renewal',
}

export interface OrderDiscountInterface {
    sst: number;
    total: number;
    amount: number;
    percentage: number; // 0 for flat rated discounts
}

export interface InvoiceInterface {
    id: string;
    hide?: boolean;
    total: number;
    amount: number;
    sst_total: number;
    trial_order: boolean;
    sst_percentage: number;
    discount_total: number;
    user_id: string;
    payment_date: string;
    latest_payment_id: string;
    expiry_date: string;
    currency: string;
    remarks: string[];
    orders: OrderInterface[];
    status: InvoiceStatuses;
    payment_status: 'unpaid' | 'failed' | 'expired' | 'paid' | 'refunding' | 'refunded' | 'billed';
    reference_number: string;
    stripe_payment_method_id: string;
    creation_date: string;
    invoice_number: string;
    receipt_files: RECEIPT_FILES;
    receipts?: File[];
    client?: userDetails;
}

export enum InvoiceStatuses {
    OPEN = 'open',
    CANCELED = 'canceled',
    COMPLETED = 'completed',
    CONFIRMED = 'confirmed',
}

export interface ResellerInvoiceInterface {
    id: string;
    total: number;
    items: number;
    amount: number;
    currency: string;
    sst_total: number;
    remarks: string[];
    sst_amount: number;
    expiry_date: string;
    reseller_id: string;
    payment_date: string;
    creation_date: string;
    sst_percentage: number;
    discount_total: number;
    invoice_number: string;
    status: InvoiceStatuses;
    latest_payment_id: string;
    requires_shipping: boolean;
    receipt_files: RECEIPT_FILES;
    payment_status: PaymentStatuses;
    orders: ResellerInvoiceOrderInterface[];

    receipts?: File[];
    reseller?: ResellerMinifiedInterface;
}

export interface ResellerInvoiceOrderInterface {
    id: string;
    total: number;
    amount: number;
    quantity: number;
    sst_total: number;
    sst_amount: number;
    unit_price: number;
    chip_type: ChipType;
    order_number: string;
    discount: {
        on_invoice: OrderDiscountInterface;
        on_checkout: OrderDiscountInterface;
    };
    title: string;
    currency: string;
    remarks: string[];
    invoice_id: string;
    product_id: string;
    description: string;
    creation_date: string;
    delivery_date: string;
    tracking_link: string;
    tracking_code: string;
    discount_total: number;
    order_type: OrderTypes;
    auto_fulfilled: boolean;
    delivery_status: FulfillmentOrderRequestStatuses;
    order_type_details: OrderType;
}

export interface RECEIPT_FILES {
    client: _FILE[];
    finance: _FILE[];
    payment_verfication_details: {
        txn_id: string;
        payment_date: string;
        payment_method: PaymentMethods | '';
    };
}

export interface _FILE {
    url: string;
    size: string;
    file_name: string;
    creation_date?: string;
}

export interface PaymentInterface {
    id: string;
    total: number;
    payment_number: string;
    user_id: string;
    payment_date: string;
    currency: string;
    expiry_date: string;
    receipt_details: {
        billed_to: CREDITCARD_BILLING_ADDRESS;
        sent: {
            on: string;
            to: string;
        }[];
    };
    invoices: InvoiceInterface[];
    remarks: string[];
    payment_status: PaymentStatuses;
    reference_number: string;
    payment_method:
        | PaymentMethods.credit_card
        | PaymentMethods.wallet_credit
        | PaymentMethods.bank_transfer
        | PaymentMethods.manual
        | PaymentMethods.coupon_code
        | '';
    stripe_payment_method_id: string;
    stripe_payment_intent_id: string;
    stripe_public_secret_id: string;
    creation_date: string;
    invoice_numbers: string[];
}

export enum PaymentMethods {
    manual = 'manual',
    credit_card = 'credit_card',
    coupon_code = 'coupon_code',
    wallet_credit = 'wallet_credit',
    bank_transfer = 'bank_transfer',
    monthly_statement = 'monthly_statement',
}

export enum PaymentStatuses {
    PAID = 'paid',
    BILLED = 'billed',
    UNPAID = 'unpaid',
    FAILED = 'failed',
    EXPIRED = 'expired',
    REFUNDED = 'refunded',
    REFUNDING = 'refunding',
}

export enum PaymentTypes {
    PRODUCTS = 'products',
    SUBSCRIPTION = 'subscription',
    MONTHLY_STATEMENT = 'monthly-statement',
}

export interface OrderType {
    id: string;
    description: string;
}

export interface ProductTrackInterface {
    id: string;
    amount: number;
    user_id: string;
    currency: string;
    record_type: string;
    creation_date: string;
    invoice_number?: string;
    statement_number?: string;
    billing_group_id: string;
}

export interface ShippingOptionInterface {
    id: string;
    price: number;
    currency: string;
    status: Statuses;
    description: string;
    transit_duration: ShippingTransitTimeInterface;
    exclusive_to_user_ids: string[];
    ends_on: string;
    archived: boolean;
    created_by: string;
    creation_date: string;
    destination_country: string;
    shipping_method: SHIPPING_METHOD;
    shipping_method_id: string;
    transition: ShippingTransitionInterface;
}

export interface ResellerShippingOptionInterface {
    id: string;
    price: number;
    currency: string;
    status: Statuses;
    description: string;
    transit_duration: ShippingTransitTimeInterface;
    exclusive_to_reseller_ids: string[];
    ends_on: string;
    archived: boolean;
    created_by: string;
    creation_date: string;
    destination_country: string;
    shipping_method: SHIPPING_METHOD;
    shipping_method_id: string;
    transition: ShippingTransitionInterface;
}

export interface SHIPPING_METHOD {
    id: string;
    description: string;
    requires_shipping: boolean;
}

export interface ShippingTransitionInterface {
    id: string;
    price: number;
    starts_on: string;
    created_by: string;
    shipping_method: SHIPPING_METHOD;
    shipping_method_id: string;
    transit_duration: ShippingTransitTimeInterface;
}

export interface ShippingTransitTimeInterface {
    to: number;
    from: number;
    in: 'days' | 'hours' | 'minutes' | 'weeks' | 'months' | 'years';
}

export interface MainInventoryInterface {
    id: string;
    sku: number;
    iccid: string;
    created_by: string;
    chip_type: ChipType;
    product_code: string;
    batch_number: number;
    creation_date: string;
    invoice_number: string;
    container_code: string;
    qr_code_value: string;
    qr_code_path: string;
    reference_number: string;
}

export interface _STATUS {
    id: string;
    icon: string;
    actions: string[];
}

export interface RequestInterfaceBase {
    id: string;
    remarks: string;
    hide?: boolean;
    department: string;
    request_type: RequestInterfaceRequestType;
    request_number: string;
    status: RequestInterfaceStatus | FulfillmentOrderRequestStatuses;
    invoice_number: string;
    request_data:
        | RequestInventoryInterface
        | RequestFulfillInventoryInterface
        | RequestVerifySOAReceiptInterface;
    approved_by: string;
    submitted_by: string;
    requested_by: string;
    creation_date: string;
}

export interface RequestInterface extends RequestInterfaceBase {
    request_data: RequestInventoryInterface;
}

export interface RequestInterfacePaymentSOA extends RequestInterfaceBase {
    request_data: RequestVerifySOAReceiptInterface;
}

export interface RequestInterfaceFulfillment extends RequestInterfaceBase {
    request_data: RequestFulfillInventoryInterface;
}

export enum RequestInterfaceStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    CANCELLED = 'cancelled',
}

export enum FulfillmentOrderRequestStatuses {
    SHIPPED = 'shipped',
    ARRIVED = 'arrived',
    RETURNED = 'returned',
    COLLECTED = 'collected',
    RETURNING = 'returning',
    UNFULFILLED = 'unfulfilled',
}

export enum RequestInterfaceRequestType {
    INVENTORY = 'inventory',
    VERIFYSOA = 'verifysoareceipt',
}

export interface RequestInventoryInterface {
    sim: number;
    esim: number;
    microchip: number;
    shipping: SHIPPING_OBJECT;
}

export interface RequestVerifySOAReceiptInterface {
    currency: string;
    receipts: _FILE[];
    amount_paid: number;
    payment_number: string;
    reference_number: string;
}

export interface RequestFulfillInventoryInterface {
    quantity: number;
    currency: string;
    order_number: string;
    arrival_date: string | Date;
    shipping_cost: number;
    tracking_link: string;
    tracking_code: string;
    delivery_date: string | Date;
    auto_fulfilled: boolean;
    send_shipped_email?: boolean;
    shipping: SHIPPING_OBJECT;
    sku_range: {
        to: string;
        from: string;
    };
    chip_type: ChipType;
}

export interface SHIPPING_OBJECT {
    name: string;
    city: string;
    email: string;
    state: string;
    show?: boolean;
    mobile: string;
    country: string;
    address: string;
    postal_code: string;
    shipping_option_id: string;
    shipping_option_details?: ShippingOptionInterface;
}

export interface BASE_64_ENCODED {
    body: string;
    header: string;
}

export interface MonthlyStatementInterface {
    id: string;
    total: number;
    items: number;
    amount: number;
    sst_total: number;
    sst_percentage: number;
    discount_total: number;
    latest_payment_id: string;
    yearmonth: string; // YYYY-MM
    user_id: string;
    currency: string;
    remarks: string[];
    status: string;
    balances: {
        debits: number[];
        credits: number[];
        bring_forward: number;
        closing_balance: number;
    };
    creation_date: string;
    statement_number: string;
    invoice_numbers: string[];
    invoice_details?: InvoiceInterface[];
    payment_details?: PaymentInterface[];
}

export interface ClientInventoryInterface {
    id: string;
    assigned_plans_count?: number;
    sku: number;
    iccid: string;
    user_id: string;
    device_info: ClientInventoryDeviceInfoInterface;
    assigned_by: string;
    creation_date: string;
    invoice_number: string;
    billing_group_id: string;
    suspended_by: SkuSuspensionActors;
    status: SkuStatus;
    state: SkuState;
    usages?: ClientInventoryUsageSummaryInterface;
    last_connection_details: SkuConnectionDetails;
    billing_group_details?: BillingGroup;
    chip_type: ChipType;
    billing_group_histories: ClientInventoryBillingGroupHistory[];
    auto_topup_product_id: string;
    auto_topup_settings: AutoTopupSettings;
    user_info_username: string;
    user_info_department: string;
    tags?: string[];
}

export enum SkuState {
    normal = 'normal',
    suspending = 'suspending',
    unsuspending = 'unsuspending',
}

export enum SkuStatus {
    paused = 'paused',
    active = 'active',
    suspended = 'suspended',
}

export interface ClientInventoryInterfaceMinified {
    id: string;
    sku: number;
    chip_type: ChipType;
    status: SkuStatus;
    device_info: ClientInventoryDeviceInfoInterface;
}

export interface ClientInventoryDeviceInfoInterface {
    id: string;
    name: string;
    imei: string;
    email: string;
}

export interface ClientInventoryUsageSummaryInterface {
    this_day: number;
    this_month: number;
    daily_average: number;
}

export interface CHART_DATA {
    x: string;
    y: number;
}

export interface AlertHistoryInterface {
    type: 'billing_group' | 'sku';
    value: string | number;
}

export interface AlertInterface {
    id: string;
    filter_by: string;
    record_type: 'alert' | 'history';
    user_id: string;
    alert_who: {
        emails: {
            enable: boolean;
            values: string[];
        };
    };
    alert_when: {
        high_usage: {
            unit: number;
            enable: boolean;
            balance: number;
            duration: 'hour' | 'day';
        };
        no_connection: {
            unit: number;
            enable: boolean;
            duration: 'hour' | 'day';
        };
        low_balance: {
            enable: boolean;
            balance: number; // in percentage
        };
    };
    sku: number; // for history record type
    billing_group_id: string; // for history record type
    log_descriptions: string[];
    subscribers: {
        type: 'billing_group' | 'sku';
        value: string | number;
        histories: AlertInterface[];
    }[];
    title: string;
    creation_date: string;
}

export interface CouponInterface {
    id: string;
    code: string;
    title: string;
    user_id: string;
    end_date: string;
    currency: string;
    order_id: string;
    start_date: string;
    created_by: string;
    max_used_by: number;
    used_counter: number;
    creation_date: string;
    discount_amount: number;
    max_used_per_user: number;
    valid_for_products: string[];
    record_type: 'usage' | 'code';
    code_type: 'discount';
    discount_amount_in_percentage: boolean;
    status: 'active' | 'inactive' | 'limit_reached' | 'expired';
}

export interface APP_PIC_SETTINGS {
    department: 'fulfillment' | 'finance' | 'core';
    emails: string[];
}

export enum SkuSuspensionActors {
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT',
    SYSTEM = 'SYSTEM',
}

export interface SKU_REPORT {
    sku: number;
    iccid: string;
    assigned_plans_count?: number;
    client_id: string;
    data_unit_per: string;
    invoice_number: string;
    suspended_by: SkuSuspensionActors;
    balances: ClientInventoryBalanceInterface;
    billing_group_id: string;
    last_connection_details: {
        date: string;
        details: PreparedRateOperatorsInterface;
    };
    device_info: ClientInventoryDeviceInfoInterface;
    tags: string[];
    chip_type: ChipType;
    status: SkuStatus.active | SkuStatus.suspended;
    state: SkuState;
    billing_group_histories: ClientInventoryBillingGroupHistory[];
    charts: {
        type: 'daily';
        data: CHART_DATA[];
    };
    client: string;
    username: string;
    department: string;
    billing_group: BillingGroup;
    usages: ClientInventoryUsageSummaryInterface;
    paused_by_network: string;
}

export interface SKU_BALANCE {
    data_amount?: string;
    data_balance?: number;
    data_used?: string;
    sku?: string;
    sku_data_transaciton_id?: string;
    last_data_session?: {
        address?: string;
        country?: string;
        created_at?: string;
        latitude?: number | string;
        longitude?: number | string;
        mccmc?: string;
        operator?: string;
    };
}

export interface LIVE_LOCATION {
    sku: number;
    latitude: number;
    longitude: number;
    address: string;
    accuracy: number;
    last_connection_date: string;
}

export interface LAST_CONNECTED {
    area: number;
    cell: number;
    cellId: string;
    lastDate: string;
    lat: number;
    lon: number;
    mcc: number;
    net: number;
    range: number;
    unit: number;
}

export interface SKU_LIST {
    sku: number;
    iccid?: string;
    device_id: string;
    device_name: string;
    imei: string;
}

export enum SortDirections {
    ASC = 'asc',
    DESC = 'desc',
}

export interface EMAILING {
    to_client_cc: string[];
    to_client_bcc: string[];
    to_internal_cc: string[];
    to_internal_bcc: string[];
}

export interface Industry {
    id: number;
    industry: string;
}

export enum ResellerTypes {
    USUAL = 'usual',
    DEFAULT = 'default',
}

export interface ResellerInterface {
    id: string;
    email: string;
    mobile: string;
    company: string;
    status: Statuses;
    short_id: number;
    last_name: string;
    created_by: string;
    first_name: string;
    creation_date: string;
    alternate_address: string;
    inventory: MainInventoryTrack;
    address: ResellerAddressInterface;
    industry_id: number;
    payment: {
        allow: boolean;
        currency: string;
        cards: CREDITCARD[];
        billing_type: ClientBillingTypes;
        tax: {
            enable: boolean;
            percentage: number;
        };
        postpaid: {
            total: number;
            received: number;
            cap: PostpaidCapSetting;
            current_statement_id: string;
            postpaid_customer_account: string;
        };
        wallet: {
            balance: number;
        };
        methods: {
            wallet: boolean;
            creditcard: boolean;
            stripe: {
                customer_id: string;
                application_key: string;
                application_secret_key: string;
            };
        };
    };
    remarks?: string[];
    settings: {
        logos: {
            portal: Logo & {
                favicon_url?: string;
            };
            invoice: Logo;
        };
        subscription_management: {
            apn_display_management: boolean;
        };
        colors?: Record<string, string>;
        hide_signup?: boolean;
        hide_terms_of_service?: boolean;
    };
    privileges: string[];
    reseller_type: ResellerTypes;
    industry: Industry;

    account_statement_summary?: STATEMENT_SUMMARY;
    custom_from_email?: string;
}

export interface Logo {
    url: string;
    enable: boolean;
    width?: number;
}

export interface INVENTORY_REQUEST {
    sim: number;
    esim: number;
    microchip: number;
    submitting: boolean;
    shipping: SHIPPING_OBJECT;
}

/**
 * @deprecated use `StockType` instead (shared/models/enums/stock-types)
 */
export enum StockType {
    ICCID = 'iccid',
    SKU = 'sku',
}

export interface STATEMENT_SUMMARY {
    month: string;
    currency: string;
    months: MonthsSince[];
    total_amount_due: number;
    total_amount_received: number;
}

export enum TaskStatuses {
    FAILED = 'failed',
    PENDING = 'pending',
    RUNNING = 'running',
    DONE = 'done',
    COMPLETED = 'completed',
    TERMINATED = 'terminated',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    FINISHED = 'finished',
    NEW = 'new',
    FIXED = 'fixed',
}

export enum ClientReportRequestModes {
    SOA = 'soa',
    INVOICE = 'invoice',
    CLIENT_SKU_SEARCH = 'sku',
    SOA_SUMMARY = 'soa-summary',
    SKU_LIST = 'sku-list-report',
    SOA_DOWNLOAD = 'soa-download',
    CLIENT_DASHBOARD = 'dashboard',
    LIVE_LOCATION = 'live-location',
    CLIENT_CDR_REPORT = 'cdr-report',
    CLIENT_INVENTORY_REPORT = 'sim-inventory-report',
    CLIENT_SIM_DATA_USAGE_REPORT = 'sim-data-usage-report',
}

export interface ClientCDRReportFilters {
    to: string;
    from: string;
}

export interface ReportHistoryInterface {
    id: string;
    s3_key: string;
    status: TaskStatuses;
    creation_date: string;
    report_data: ClientCDRReportFilters;
    report_type: ClientReportRequestModes;
}

export enum CrmReportRequestModes {
    SKU_SEARCH = 'sku',
}

export enum CrmOperationsManagerModes {
    LOGINAS = 'login-as',
}

export enum CrmFinanceManagerModes {
    RESELLERS_PRODUCTS = 'resellers-products',
    CREATE_WALLET_ORDER = 'create-wallet-order',
}

export enum ClientSystemAlertsTabPages {
    MANAGE = 'manage',
    SUBSCRIBE = 'subscribe',
}

export enum ClientManageAlertsTabsPages {
    HISTORY = 'history',
    LIST = 'list',
}

export interface CountryWithOperators {
    name: string;
    operators: OperatorForCountry[];
}

export interface OperatorForCountry {
    country: string;
    name: string;
    operatorcode: string;
    dataPrice?: string;
    smsPrice?: string;
}
