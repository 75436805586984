import { SimpleQueryStore, createSimpleQueryStoreEffects } from '@app/utils/SimpleQueryStore';

export const countrySelectStore = new SimpleQueryStore(
    'countrySelect',
);

export const CountrySelectEffects = createSimpleQueryStoreEffects(
    countrySelectStore,
    'getSupportedCountries',
);
