import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import * as fromApp from './app.reducer';
import { environment } from '../../../environments/environment';

export interface State {
    [fromApp.appKey]: fromApp.State;
    router: RouterReducerState;
}

export const reducers: ActionReducerMap<State> = {
    [fromApp.appKey]: fromApp.reducer,
    router: routerReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
