import { Resources } from '@app/modules/shared/models/resources';
import { createReducer, on } from '@ngrx/store';
import { SessionInfo } from 'src/app/modules/core/config/config';
import { environment } from 'src/environments/environment';

import * as AppActions from '../actions/app.actions';
import { ProxyingInfo } from '@app/modules/core/services/auth.service';
import { Branding } from '@app/modules/core/services/resources.service';

export const appKey = 'app';

export interface State {
    sessionInfo: SessionInfo;
    resources: Resources;
    proxyingUser: ProxyingInfo;
    branding: Branding;
}

export const initialState: State = {
    sessionInfo: null,
    resources: null,
    proxyingUser: null,
    branding: null,
};

export const reducer = createReducer(
    initialState,

    on(AppActions.loadSessionInfo, (state) => ({
        ...state,
    })),
    on(AppActions.loadSessionInfoSuccess, (state, { data }) => ({
        ...state,
        sessionInfo: data,
    })),
    on(AppActions.loadSessionInfoFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(AppActions.loadResourcesSuccess, (state, { resources }) => ({
        ...state,
        resources,
    })),
    on(AppActions.loadResourcesFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(AppActions.loadBrandingSuccess, (state, branding) => ({
        ...state,
        branding,
    })),
    on(AppActions.loadBrandingFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(AppActions.loadProxyingUserSuccess, (state, { proxyingUser }) => ({
        ...state,
        proxyingUser,
    })),
    on(AppActions.loadProxyingUserFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(AppActions.stateTeardown, () => initialState)
);
