import { ProxyingInfo } from '@app/modules/core/services/auth.service';
import { Branding } from '@app/modules/core/services/resources.service';
import { Resources } from '@app/modules/shared/models/resources';
import { createAction, props } from '@ngrx/store';
import { SessionInfo } from 'src/app/modules/core/config/config';

export const startUp = createAction('[App] Start-up', props<{ data: SessionInfo }>());

export const stateTeardown = createAction('[App] State teardown');

export const loadSessionInfo = createAction('[App] Load Session Info');

export const loadSessionInfoSuccess = createAction(
    '[App] Load Session Info Success',
    props<{ data: SessionInfo }>()
);

export const loadSessionInfoFailure = createAction(
    '[App] Load Session Info Failure',
    props<{ error: any }>()
);

export const loadResources = createAction('[App] Load Resources');

export const loadResourcesSuccess = createAction(
    '[App] Load Resources Success',
    props<{ resources: Resources }>()
);

export const loadResourcesFailure = createAction(
    '[App] Load Resources Failure',
    props<{ error: any }>()
);

export const loadBranding = createAction('[App] Load Branding');

export const loadBrandingSuccess = createAction('[App] Load Branding Success', props<Branding>());

export const loadBrandingFailure = createAction(
    '[App] Load Branding Failure',
    props<{ error: any }>()
);

export const loadProxyingUser = createAction('[App] Load Proxying User');

export const loadProxyingUserSuccess = createAction(
    '[App] Load Proxying User Success',
    props<{ proxyingUser: ProxyingInfo }>()
);

export const loadProxyingUserFailure = createAction(
    '[App] Load Proxying User Failure',
    props<{ error: any }>()
);
