import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpClient
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CoreService } from '../modules/core/services/core.service';
import { FunctionsService } from '../modules/core/services/functions.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        private coreService: CoreService,
        private functionsService: FunctionsService,
        private httpClient: HttpClient,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let skip_message = false
        let skip_intercept = false

        if (request.headers.has('skip-alert')) {
            skip_message = true;
            request = request.clone({
                headers: request.headers.delete('skip-alert')
            });
        }
        if (request.headers.has('skipAlert')) {
            skip_message = true;
            request = request.clone({
                headers: request.headers.delete('skipAlert')
            });
        }

        if (request.headers.has('skip')) {
            skip_intercept = true
            request = request.clone({
                headers: request.headers.delete('skip')
            });
        } else {
            const token = this.coreService.isLoggedIn() ? this.coreService.getSessionInfo().token : ""
            if (token) {
                request = request.clone({ headers: request.headers.set('Authorization', token) });
            }
            if (!request.headers.has('Content-Type')) {
                request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            }
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (!skip_intercept) {
                    let status = +error.error?.status || +error.status;

                    if (!error || !status) {
                        this.functionsService.showAlertMessage('Something went wrong. Try again or contact us.');
                        return throwError(error);
                    }

                    let needsLogout = false;

                    if (status === 401) {
                        needsLogout = true;
                    } else if (status === 403) {
                        let errorMessage = error.error.message;
                        if (
                            [
                                'Expired Token',
                                'Missing Authentication Token',
                                'Invalid Signature',
                            ].includes(errorMessage)
                        ) {
                            needsLogout = true;
                        }
                    }

                    if (needsLogout) {
                        this.functionsService.showAlertMessage('Session expired.');
                        this.coreService.logMeOut();
                    } else if (!skip_message) {
                        // Error comes from Solutions API
                        const errorMessage = error?.error?.error || '';
                        if (errorMessage) {
                            this.functionsService.showErrorMessage(errorMessage);
                        }
                    }
                }

                return throwError(error);
            }));
    }
}
