import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APP_CONFIG, BASE_64_ENCODED, IAppConfig, userDetails } from '../config/config';
import { SystemUserTypes } from '@shared/models/enums/system-user-types';
import { ResourcesService } from './resources.service';
import { PresignedPolicy, DateFormat, ModuleNameUpload, ModuleNameDownload } from '@core/types/common'

declare const require: any
var dataURLtoBlob = require('blueimp-canvas-to-blob')

@Injectable({
    providedIn: 'root'
})
export class FunctionsService {

    singleExecutionSubscription: Subscription
    private presigned_policies: { module: string; policy: PresignedPolicy }[] = []

    constructor(
        private toastr: ToastrService,
        private httpClient: HttpClient,
        private recaptchaV3Service: ReCaptchaV3Service,
        private resourcesService: ResourcesService,
        @Inject(APP_CONFIG) private readonly config: IAppConfig,
    ) {
    }

    isAdmin(u: userDetails): boolean {
        return u.user_category === SystemUserTypes.ADMIN
    }

    isEmail(em: string): boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(em).toLowerCase());
    }

    isString(s: any): boolean {
        return typeof s === typeof ""
    }

    showSuccessMessage(m: string, title = ""): void {
        this.toastr.success(m, title)
    }

    showErrorMessage(m: string, title = ""): void {
        this.toastr.error(m, title)
        this.logToConsole(m)
    }

    showAlertMessage(m: string, title = ""): void {
        this.toastr.info(m, title)
    }

    showWarningMessage(m: string, title = ""): void {
        this.toastr.warning(m, title)
    }

    logToConsole(w: any, other: any = null): void {
        if (!environment.production) {
            other ? console.log(w, other) : console.log(w)
        }
    }

    copyObject<T>(obj: T) {
        return JSON.parse(JSON.stringify(obj)) as T;
    }

    /**
     * @deprecated use ucfirst pipe instead
     * @see ucfirst.pipe.ts
     */
    ucfirst(w: string): string {
        if (!w) return w
        w = w.trim().toLowerCase();
        let f = w[0].toUpperCase();
        return f + w.substr(1);
    }

    ucwords(w: string): string {
        if (!w) return w
        let ww = w.trim().toLowerCase().split(" ");
        return ww.filter(ww => ww.trim() ? true : false).map(ww => this.ucfirst(ww)).join(" ")
    }

    forceInt(s: string): number {
        return +s
    }

    /**
     * @deprecated use forceUrl pipe instead
     * @see force-url.pipe.ts
     */
    forceUrl(s: string) {
        return s.toLowerCase().startsWith("http") ? s : `http://${s}`
    }

    /**
     * @deprecated use formatBytes pipe instead
     * @see format-bytes.pipe.ts
     */
    formatBytes(bytes: number, decimals = 2): string {
        if (!bytes) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    isNumeric(n: any): boolean {
        return !isNaN(parseFloat(`${n}`)) && isFinite(n)
    }

    toFixed(num: number, fixed = 2): number {
        const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return parseFloat(num.toString().match(re)[0]);
    }

    isToday(d: string): boolean {
        const today = this.getDateCustom()
        let date = this.getDateCustom(d)
        return date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
    }

    addDate(d: Date, f: DateFormat): Date {
        if (f.date) {
            d.setDate(d.getDate() + f.date);
        }

        if (f.months) {
            d.setMonth(d.getMonth() + f.months);
        }

        if (f.years) {
            d.setFullYear(d.getFullYear() + f.years);
        }

        if (f.hours) {
            d.setHours(d.getHours() + f.hours);
        }

        if (f.minutes) {
            d.setMinutes(d.getMinutes() + f.minutes);
        }

        if (f.seconds) {
            d.setSeconds(d.getSeconds() + f.seconds);
        }

        return new Date(d);
    }

    subDate(d: Date, f: DateFormat): Date {
        if (f.date) {
            d.setDate(d.getDate() - f.date);
        }

        if (f.months) {
            d.setMonth(d.getMonth() - f.months);
        }

        if (f.years) {
            d.setFullYear(d.getFullYear() - f.years);
        }

        if (f.hours) {
            d.setHours(d.getHours() - f.hours);
        }

        if (f.minutes) {
            d.setMinutes(d.getMinutes() - f.minutes);
        }

        if (f.seconds) {
            d.setSeconds(d.getSeconds() - f.seconds);
        }

        return d;
    }

    sortAlphabetically(arr: any[], key: string): any[] {
        let ret = arr.sort(function (a, b) {
            a[key] = a[key] ? a[key] : "";
            b[key] = b[key] ? b[key] : "";
            var textA = a[key].toUpperCase();
            var textB = b[key].toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        return ret;
    }

    async base64_from_file(file: File): Promise<BASE_64_ENCODED> {
        return new Promise((done, nope) => {
            const reader: FileReader | any = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                done(this.prep_base64(reader.result));
            };
            reader.onerror = (err) => {
                this.logToConsole(`getBase64fromFile failed.`, err)
                nope(err);
            };
        });
    }

    prep_base64(str: string): BASE_64_ENCODED {
        const spliced = str.split(',');
        const header = spliced[0];
        spliced.shift();
        return {
            header: header,
            body: spliced.join('')
        }
    }

    arrayUnique(a: any[]): any[] {
        return a.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    }

    /**
     * @deprecated use angular build in date pipe instead
     * @see https://angular.io/api/common/DatePipe
     */
    datePimped(d: string, add_time: boolean = false): string {
        if (!d) return ""
        let dd = this.getDateCustom(d);
        return this.datePimper(dd, add_time)
    }

    /**
     * @deprecated use angular build in date pipe instead
     * @see https://angular.io/api/common/DatePipe
     */
    datePimper(dd: Date, add_time = false): string {
        let mm = this.months();
        if (!add_time) {
            return `${mm[dd.getMonth()]} ${dd.getDate()}, ${dd.getFullYear()}`;
        }
        return `${mm[dd.getMonth()]} ${dd.getDate()}, ${dd.getFullYear()} ${this.ampm(dd)}`;
    }

    ampm(dt: Date): string {
        let hours = dt.getHours();
        let minutes: number | string = dt.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutes} ${ampm}`;
    }

    months(full = false): string[] {
        if (!full) {
            return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        }
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }

    years(start_year: number = null): number[] {
        const default_start_year: number = 2021
        var cur_year: number = this.getDateCustom().getFullYear()
        var years: number[] = []
        start_year = start_year || default_start_year;
        while (start_year <= cur_year) {
            years.push(start_year++);
        }
        return years;
    }

    getDateCustom(d: string = "", utc = " UTC"): Date {
        if (!d) {
            return new Date()
        }
        return new Date(d.replace(/-/g, "/") + utc)
    }

    dateDBFormat(d: Date, include_time = true): string {
        const force2 = (d: number): string => {
            return d < 10 ? `0${d}` : d.toString()
        }
        let dd = [d.getFullYear(), force2(d.getMonth() + 1), force2(d.getDate())]
        let ddd = [force2(d.getHours()), force2(d.getMinutes()), force2(d.getSeconds())]
        return include_time ? `${dd.join('-')} ${ddd.join(':')}` : dd.join('-')
    }


    validURL(url: string): boolean {
        url = `${url}`.trim()
        if (!url || url.indexOf('.') === -1) return false
        url = url.split("?")[0]
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return (!!pattern.test(url))
    }

    async directUpload(
        file: File,
        return_full_url = true,
        moduleName: ModuleNameUpload = "receipts-upload",
        content_type = ""
    ): Promise<string> {
        const base64File: BASE_64_ENCODED = await this.base64_from_file(file)

        //Initiate upload
        let result: PresignedPolicy | Observable<PresignedPolicy> = this.presigned_policies.find(pp => pp.module)?.policy

        if (!result) {
            result = await this.resourcesService.getPolicy(moduleName).toPromise()
        }

        if (!this.presigned_policies.find(pp => pp.module)) {
            // Remember policy
            this.presigned_policies.push({
                module: moduleName,
                policy: result
            })
        }
        let policy_decoded = JSON.parse(atob(result.fields.Policy))
        let formData = new FormData()
        formData.append('key', result.fields.key);
        let acl = policy_decoded.conditions.find(c => c.acl)
        if (acl) {
            formData.append('acl', acl.acl);
        }
        if (!content_type) {
            switch (file.name.split(".").pop().toLowerCase()) {
                case "png":
                case "jpg":
                case "gif":
                case "jpeg":
                    content_type = "image/jpeg"
                    break;
                case "pdf":
                    content_type = "application/pdf"
                    break;
                default:
                    content_type = base64File.header.replace("data:", "")
                    content_type = content_type.replace(";base64", "")
                    break;
            }
        }
        formData.append('Content-Type', content_type);
        formData.append('Policy', result.fields.Policy);
        formData.append('X-Amz-Algorithm', result.fields['X-Amz-Algorithm']);
        formData.append('X-Amz-Credential', result.fields['X-Amz-Credential']);
        formData.append('X-Amz-Date', result.fields['X-Amz-Date']);
        formData.append('X-Amz-Signature', result.fields['X-Amz-Signature']);
        formData.append('bucket', result.fields.bucket);
        let file_name = file.name.split(" ").join("_");
        [" ", ")", "(", "#", "'"].map(character => file_name = file_name.split(character).join("_"))
        formData.append('file', dataURLtoBlob([base64File.header, base64File.body].join(',')), file_name)
        await this.httpClient.post(`${result.url}/`, formData, { headers: { skip: "true" } }).toPromise()
        let ret = return_full_url ? environment.files_url : ""
        ret += result.fields.key.replace("${filename}", file_name)
        return ret
    }

    async directDownload(
        private_url: string,
        id: string,
        fileName = "",
        moduleName: ModuleNameDownload = "receipts-download"
    ): Promise<boolean> {
        const checkedFileName = fileName || private_url.split("/").pop()
        const temp_public_url = await this.resourcesService.getFileUrl(moduleName, id, checkedFileName).toPromise()
        const element = document.createElement('a')
        element.setAttribute('href', temp_public_url)
        element.setAttribute('target', "_blank")
        element.setAttribute('download', fileName || checkedFileName)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
        return true
    }

    openPopUp(url: string) {
        const newWindow = window.open(url, '_blank')
        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
            this.showAlertMessage("We attempted to open the document in a new window but a popup blocker is preventing it from opening, please disable popup blockers for this site.")
            return
        }
        newWindow?.focus()
    }

    duplicatesInArray(arr: any[]) {
        let r = false
        r = arr.some((e, i) => {
            return arr.indexOf(e) !== i
        })
        return r
    }

    flattenArray(arr: any[]) {
        return arr.reduce((acc, cur) => {
            return acc.concat(cur)
        }, [])
    }

    getRegexes() {
        return {
            mobile: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/im,
            url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
        }
    }

    async getRecaptchaToken(action: "signin" = "signin"): Promise<string> {
        if (this.singleExecutionSubscription) this.singleExecutionSubscription.unsubscribe();
        return new Promise((yeap, nope) => {
            this.singleExecutionSubscription = this.recaptchaV3Service.execute(action).subscribe(
                (token) => {
                    yeap(token)
                },
                (error) => {
                    nope(error)
                }
            );
        })
    }
}
