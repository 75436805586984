export const isJtSKU = (sku: number) => {
    return /^2/.test(`${sku}`);
};

export const isTelnaSKU = (sku: number) => {
    return /^3/.test(`${sku}`);
};

export const isWebbingSKU = (sku: number) => {
    return /^4/.test(`${sku}`);
};
