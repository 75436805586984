export function hexToRGB(hex: string) {
    if (hex.startsWith('#')) {
        hex = hex.slice(1);
    }
    if (hex.length === 3) {
        hex.split('')
            .map((a) => `${a}${a}`)
            .join('');
    }

    const int = parseInt(hex, 16);
    const r = (int >> 16) & 255;
    const g = (int >> 8) & 255;
    const b = int & 255;

    return `${r},${g},${b}`;
}
