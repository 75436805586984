import { environment } from 'src/environments/environment';

const getKey = (k: string) => {
    return `SOS_UI_${environment.envName}:${k}`;
};

export const storageHelper = {
    set(key: string, value: any) {
        key = getKey(key);
        value = JSON.stringify(value);
        window.localStorage.setItem(key, value);
    },

    get<T>(key: string, defaultValue?: T) {
        key = getKey(key);
        const fromStorage = window.localStorage.getItem(key);

        if (fromStorage === undefined || fromStorage === null) {
            return defaultValue;
        }

        let value: T;
        try {
            value = JSON.parse(fromStorage) as T;
        } catch (e) {
            value = defaultValue;
        }

        return value;
    },

    remove(key: string) {
        key = getKey(key);
        window.localStorage.removeItem(key);
    },
};
