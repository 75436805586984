export const environment = {
    production: false,
    envName: 'dev',
    google_keys: {
        recaptcha: '6Ld3gucdAAAAAG4EVmMVRKNbhKZfrvDndkkwGXTJ',
    },
    stripe_key: 'pk_test_YLuy4uAMteA9ToZlUslbdRHu',
    files_url: `https://s3-eu-west-1.amazonaws.com/newdev-solutions-assets/`,
    api_endpoint: 'https://newdev-solutions-api.flexiroam.com/',
    // api_endpoint: "http://127.0.0.1:4111/",
    here_key: 'JKvR38QL_LV2WRixQ6xRGbhxRdausQb8VN2H62dcaVo',
    // @see docs for config https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md#telemetry-config-array
    awsRumConfig: {},
    awsRumAppId: '',
    enableDiscountCodeModule: false,
};

if (
    typeof window !== 'undefined' &&
    (window.location.host === 'newdev-solutions.flexiroam.com' ||
        window.location.host === 'dev-nearshore.flexiroam.com')
) {
    environment.api_endpoint = 'https://newdev-solutions-api.flexiroam.com/';
}

export const API_HOST = environment.api_endpoint.slice(0, -1);
export const RATESHEETS_API_HOST = 'https://rate-sheet-solution.eu-west-1.dev.flexiroam.com';
