const mapMcc = {
    // China
    461: 460,
    // India
    405: 404,
    406: 404,
    // Japan
    441: 440,
    // United Kingdom
    235: 234,
    // United States
    311: 310,
    312: 310,
    313: 310,
    314: 310,
    315: 310,
    316: 310,
};

export function getMccFromOperatorCode(operatorCode: string) {
    let mcc = +operatorCode.split('-')[1].slice(0, 3);

    if (mapMcc[mcc] !== undefined) {
        mcc = mapMcc[mcc];
    }

    return mcc.toString();
}
