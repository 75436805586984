import { createReducer, on, createAction, props, createFeatureSelector, createSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ResourcesService } from '@app/modules/core/services/resources.service';
import { Store } from '@ngrx/store';

export interface SimpleQueryStoreState<T> {
    list: T[];
    error: null | Error;
    loading: boolean;
}

export class SimpleQueryStore<T> {
    constructor(
        public readonly name: string,
    ) {}

    initialState: SimpleQueryStoreState<T> = {
        list: [],
        error: null,
        loading: false,
    };

    selectState = createFeatureSelector<SimpleQueryStoreState<T>>(
        this.name,
    );
    selectList = createSelector(
        this.selectState,
        (s) => s.list,
    );
    selectLoading = createSelector(
        this.selectState,
        (s) => s.loading,
    );

    actions = {
        load: createAction(`[${this.name}] Load`),
        loadStarted: createAction(`[${this.name}] Load Started`),
        loadFailed: createAction(
            `[${this.name}] Load Failed`,
            props<{ error: any }>(),
        ),
        loadSuccess: createAction(
            `[${this.name}] Load Success`,
            props<{ list: any }>(),
        ),
    };

    reducer = createReducer(
        this.initialState,

        on(this.actions.loadStarted, (state) => ({
            ...state,
            loading: true,
        })),
        on(this.actions.loadSuccess, (state, { list }) => ({
            ...state,
            list: [...list],
            loading: false,
        })),
        on(this.actions.loadFailed, (state, { error }) => ({
            ...state,
            error,
            loading: false,
        })),
    );
}

export function createSimpleQueryStoreEffects<T>(
    simpleQueryStore: SimpleQueryStore<T>,
    methodNameToGetResources: string,
) {
    @Injectable()
    class SimpleQueryStoreEffects {
        constructor(
            private actions$: Actions,
            private resourcesService: ResourcesService,
            private store: Store,
        ) {}

        load$ = createEffect(<any>(() => {
            return this.actions$.pipe(
                ofType(simpleQueryStore.actions.load),
                withLatestFrom(
                    this.store.select(simpleQueryStore.selectList),
                    this.store.select(simpleQueryStore.selectLoading),
                ),
                filter(([,,loading]) => {
                    return !loading;
                }),
                switchMap(([,list]) => {
                    if (list?.length) {
                        return;
                    }

                    this.store.dispatch(simpleQueryStore.actions.loadStarted());

                    return this.resourcesService[methodNameToGetResources]().pipe(
                        map((res: any) => {
                            return simpleQueryStore.actions.loadSuccess({ list: res });
                        }),
                        catchError((error) =>
                            of(simpleQueryStore.actions.loadFailed({ error }))
                        ),
                    );
                }),
            );
        }));
    }

    return SimpleQueryStoreEffects;
}
