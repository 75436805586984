import { BillingGroupFrequencies } from '@app/modules/core/config/config';
import { environment } from 'src/environments/environment';

export const basicBillingGroupFrequencies: BillingGroupFrequencies[] = [
    !environment.production ? 'hourly' as any : null,
    BillingGroupFrequencies.DAILY,
    BillingGroupFrequencies.WEEKLY,
    BillingGroupFrequencies.MONTHLY,
    BillingGroupFrequencies.QUARTERLY,
    BillingGroupFrequencies.HALF_YEARLY,
    BillingGroupFrequencies.YEARLY,
];
