import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { setUpAwsRum } from './aws-rum.config';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

(() => {
    setTimeout(() => {
        const link = document.createElement('link');
        link.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
        link.setAttribute('rel', 'stylesheet');

        document.head.appendChild(link);

        console.log('Added font link');
    }, environment.production ? 0 : 3000);
})();

// const isNewDev = typeof window !== 'undefined'
//     && window.location.host === 'newdev-solutions.flexiroam.com';

// if (
//     !isNewDev
//     && ['prod'].includes(environment.envName)
// ) {
//     setUpAwsRum();
// }

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
