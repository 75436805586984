import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
    APP_CONFIG,
    IAppConfig,
    SessionInfo,
    userDetails,
    RESOURCES,
    BillingGroup,
    ProductInterface,
    CREDITCARD_BILLING_ADDRESS,
    TAB,
    ClientTabChildPages,
    ClientTabRootPages,
    SessionMetaData,
    ClientBillingTypes,
    BillingGroupFrequencies,
    ResellerInterface,
    ProductTypes,
    Statuses,
    BillingGroupChargeUpon,
    BillingGroupStatuses,
    BillingGroupModels,
    STATEMENT_SUMMARY,
} from '../config/config';
import { FunctionsService } from './functions.service';
import * as AppActions from '@root-store/actions/app.actions';
import * as AppSelectors from '@root-store/selectors/app.selectors';
import { SystemUserTypes } from '@shared/models/enums/system-user-types';
import { ResourcesService } from './resources.service';
import { first, map, tap } from 'rxjs/operators';
import { jsonCopy, storageHelper } from '@app/utils';
import {
    LSK_RatesheetsApiCredentialsId,
    LSK_RatesheetsApiPass,
    LSK_RatesheetsApiToken,
} from '@app/types/localStorageKeys';
import { BillingGroupChargingModel, BillingGroupPricingModel } from '@app/types/billingGroups';

@Injectable({
    providedIn: 'root',
})
export class CoreService {
    session_data: SessionInfo = null;
    private session_data_ob = new BehaviorSubject(this.session_data);
    session_dataObservable = this.session_data_ob.asObservable();

    session_vname: string = 'session_info';

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private functionService: FunctionsService,
        @Inject(APP_CONFIG) private config: IAppConfig,
        private store: Store
    ) {
        this.importDataFromLocalStorage();
    }

    setSessionData(si: SessionInfo, emit = true, dispatch = true): void {
        this.session_data = si;
        if (emit) {
            this.session_data_ob.next(si);
        }
        this.functionService.logToConsole(this.session_data);
        try {
            localStorage.setItem(this.session_vname, JSON.stringify(this.session_data));
        } catch (error) {
            // Local storage is not allowed in cognito mode, try session storage instead
            sessionStorage.setItem(this.session_vname, JSON.stringify(this.session_data));
        }

        if (dispatch) {
            this.store.dispatch(
                AppActions.loadSessionInfoSuccess({
                    data: jsonCopy(this.session_data),
                })
            );
        }
    }

    importDataFromLocalStorage(): void {
        let session_info = this.getSessionInfo();
        if (!session_info) {
            return;
        }
        this.setSessionData(session_info);
    }

    goHome(): void {
        if (
            [SystemUserTypes.CRM, SystemUserTypes.ADMIN].includes(
                this.session_data.user_details.user_category
            )
        ) {
            this.router.navigateByUrl(`/${this.session_data.user_details.user_category}/dashboard`);
            return;
        }
        this.router.navigateByUrl(`/user/dashboard`);
    }

    hasPermission(permission: string, who: userDetails = null): boolean {
        who = who || this.session_data.user_details;
        if (!who) return false;
        let module_level = permission.split('.');
        return (
            who.roles.indexOf('*') > -1 ||
            (who.roles.indexOf(`${module_level[0]}.${module_level[1]}`) > -1 &&
                who.roles.indexOf(permission) > -1)
        );
    }

    async logMeIn(token: string) {
        let last_routes = this.session_data?.last_routes;
        if (!last_routes) {
            last_routes = this.session_data
                ? this.session_data.last_routes
                : {
                      b4payment: '',
                      lastvisited: '',
                      tabs: {
                          admin: {
                              profile: null,
                              invoice: null,
                          },
                          reseller: {
                              root: null,
                              profile: null,
                              products: null,
                              users: SystemUserTypes.ADMIN,
                          },
                          client: {
                              root: null,
                              invoice: null,
                              profile: null,
                              inventory: null,
                          },
                          finance: {
                              invoices: '',
                              requests: '',
                              billing_groups: BillingGroupStatuses.REQUEST,
                          },
                          crm: {
                              finance: {
                                  billing_groups: BillingGroupStatuses.REQUEST,
                              },
                              fulfillment: {
                                  orders: '',
                              },
                          },
                          fulfillment: {
                              orders: '',
                          },
                      },
                  };
        }

        this.session_data = {
            token,
            last_routes,
            proxy_user: '',
            app_busy: false,
            resources: null,
            user_details: null,
            reseller_details: null,
            cache: {
                industries: [],
                products: null,
                resellers: null,
                client_list: null,
                coverage_groups: [],
                supported_countries: null,
                supported_countries_simple: null,
                last_dashboard_client_id: '',
                imported_inventory_from_core: false,
            },
        };
        this.setSessionData(this.session_data, false, false);

        storageHelper.remove(LSK_RatesheetsApiToken);
        storageHelper.remove(LSK_RatesheetsApiPass);
        storageHelper.remove(LSK_RatesheetsApiCredentialsId);

        let session_details = await this.getSessionData();
        this.session_data.resources = session_details.resources;
        this.session_data.proxy_user = session_details.meta.proxying;
        this.session_data.user_details = session_details.user_details;
        this.session_data.reseller_details = session_details.reseller_details;
        this.setSessionData(this.session_data);
        if (this.session_data.last_routes.lastvisited) {
            this.router.navigateByUrl(this.session_data.last_routes.lastvisited);
            return;
        }
        this.goHome();
    }

    async refreshSession() {
        let session_details = await this.getSessionData();
        const clone_session_info: SessionInfo = JSON.parse(JSON.stringify(this.session_data));
        clone_session_info.resources = session_details.resources;
        clone_session_info.proxy_user = session_details.meta.proxying;
        clone_session_info.user_details = session_details.user_details;
        clone_session_info.reseller_details = session_details.reseller_details;
        this.setSessionData(clone_session_info);
    }

    isLoggedIn(): boolean {
        return !!this.getSessionInfo()?.token;
    }

    logMeOut() {
        // return
        const bouncer = () => {
            this.store.dispatch(AppActions.stateTeardown());
            try {
                localStorage.removeItem(this.session_vname);
            } catch (error) {
                // Local storage is not allowed in cognito mode, try session storage instead
                sessionStorage.removeItem(this.session_vname);
            }
            this.setSessionData(null);
            this.router.navigateByUrl('/');
        };

        if (!this.isLoggedIn()) {
            bouncer();
            return;
        }
        this.session_data.app_busy = true;
        this.setSessionData(this.session_data);

        this.httpClient.delete(this.config.apiEndpoints.auth.signoff).subscribe({
            error(err) {
                console.error('Error logging out');
                console.error(err);
            },
        });

        bouncer();
    }

    getSessionInfo(): SessionInfo {
        try {
            return JSON.parse(localStorage.getItem(this.session_vname));
        } catch (error) {
            // Local storage is not allowed in cognito mode, try session storage instead
        }
        try {
            return JSON.parse(sessionStorage.getItem(this.session_vname));
        } catch (error) {
            // Local storage is not allowed in cognito mode, try session storage instead
        }
        return null;
    }

    async getSessionData(): Promise<{
        user_details: userDetails;
        resources: RESOURCES;
        meta: SessionMetaData;
        reseller_details: ResellerInterface;
    }> {
        return (await this.httpClient
            .get(this.config.apiEndpoints.auth.session_data)
            .toPromise()) as any;
    }

    getBillingTypes(): { id: ClientBillingTypes; description: string }[] {
        return [
            {
                id: ClientBillingTypes.PREPAID,
                description: 'Prepaid',
            },
            {
                id: ClientBillingTypes.POSTPAID,
                description: 'Postpaid',
            },
        ];
    }

    initBillingGroup(): BillingGroup {
        return {
            id: '',
            price: null,
            title: '',
            status: BillingGroupStatuses.REQUEST,
            action_required: '',
            user_id: '',
            reseller_id: '',
            currency: '',
            coverage_group_id: '',
            retail_billing_group_id: '',
            remarks: [],
            settings: {
                access_fee: {
                    amount: 0,
                    enabled: false,
                },
                apn: '',
                apn_hidden: false,
                prorate: true,
                proratable: true,
                autorenew: true,
                load_value: 500,
                data_unit: 1000,
                data_unit_per: 'MB',
                load_when_value: 150,
                billing_model_id: BillingGroupModels.DATAONPOOL,
                frequency: BillingGroupFrequencies.MONTHLY,
                frequency_unit: 0,
                charge_upon: BillingGroupChargeUpon.SUBSCRIPTION,
                balances: {
                    main_data: {
                        plan: null,
                        pool_balance: 0,
                    },
                    throttle_data: {
                        plan: 0,
                        pool_balance: 0,
                    },
                },
                pricing_model: BillingGroupPricingModel.SUBSCRIPTION,
                charging_model: BillingGroupChargingModel.LOAD_BASED,
                data_plan: null,
            },
            next_bill_date: '',
            created_by: '',
            subscriptions: {
                total: 0,
                billed: 0,
            },
            creation_date: null,
            billing_model: null,
            auto_topup_product_id: '',
            auto_topup_settings: {
                enabled: false,
                quantity: 0,
                threshold: 0,
                threshold_in_percentage: true,
                count_limit: null,
            },
        };
    }

    initProduct(product_type = ProductTypes.DATAPOOL): ProductInterface {
        return {
            id: '',
            title: '',
            ends_on: '',
            price: null,
            currency: '',
            product_type,
            created_by: '',
            data_unit: null,
            archived: false,
            transition: null,
            creation_date: '',
            data_unit_per: 'MB',
            status: Statuses.ACTIVE,
            exclusive_to_user_ids: [],
        };
    }

    validateBillingDetails(billing_address: CREDITCARD_BILLING_ADDRESS): string[] {
        let billing_info_err_arr: string[] = [];
        if (!billing_address.editable) return billing_info_err_arr;
        let is_empty = (d: string) => !d || !d.toString().trim();
        if (is_empty(billing_address.full_name)) {
            billing_info_err_arr.push('full_name');
        }
        if (
            is_empty(billing_address.email) ||
            !this.functionService.isEmail(billing_address.email)
        ) {
            billing_info_err_arr.push('email');
        }
        if (is_empty(billing_address.country)) {
            billing_info_err_arr.push('country');
        }
        if (is_empty(billing_address.address)) {
            billing_info_err_arr.push('address');
        }
        if (is_empty(billing_address.state)) {
            billing_info_err_arr.push('state');
        }
        if (is_empty(billing_address.mobile)) {
            billing_info_err_arr.push('mobile');
        }
        return billing_info_err_arr;
    }

    clientManagementTabs(
        session_info: SessionInfo,
        client_details: userDetails
    ): { page: ClientTabRootPages; tabs: TAB[] } {
        let _filtered: TAB[] = [
            {
                children: [
                    {
                        icon: 'bi bi-person',
                        description: 'Profile',
                        page: ClientTabChildPages.PROFILE,
                    },
                    {
                        icon: 'bi bi-credit-card',
                        description: 'Payment methods',
                        page: ClientTabChildPages.PAYMENT,
                    },
                    {
                        icon: 'bi bi-wallet',
                        description: 'My wallet',
                        page: ClientTabChildPages.WALLET,
                    },
                    {
                        icon: 'bi bi-star',
                        description: 'Privileges',
                        page: ClientTabChildPages.PRIVILEGES,
                    },
                    {
                        icon: 'bi bi-gear',
                        description: 'Settings',
                        page: ClientTabChildPages.SETTINGS,
                    },
                    {
                        icon: 'bi bi-file-earmark-arrow-up',
                        description: 'Documents',
                        page: ClientTabChildPages.DOCUMENTS,
                    },
                    {
                        icon: 'bi bi-key',
                        description: 'API Keys',
                        page: ClientTabChildPages.API_KEYS,
                    },
                    {
                        icon: 'bi bi-file-earmark-text',
                        description: 'Policy Control',
                        page: ClientTabChildPages.POLICY_CONTROL,
                    },
                ],
                page: ClientTabRootPages.ACCOUNT_SETTINGS,
                icon: 'bi bi-gear',
                description: 'Account settings',
            },
            {
                icon: 'bi bi-box',
                description: 'SIM list',
                dataTestSelector: 'shared-side-menu_client-categories_sim-list',
                page: ClientTabRootPages.INVENTORY,
                children: [
                    {
                        icon: 'bi bi-gear',
                        description: 'SIM list',
                        page: ClientTabChildPages.INVENTORY_LIST,
                    },
                    {
                        icon: 'bi bi-plus-circle',
                        description: 'Request stock',
                        page: ClientTabChildPages.INVENTORY_REQUEST,
                    },
                ],
            },
            {
                children: [],
                icon: 'bi bi-cash-stack',
                dataTestSelector: 'shared-side-menu_client-categories_subscriptions',
                description: 'Subscriptions',
                page: ClientTabRootPages.SUBSCRIPTIONS,
            },
            {
                children: [],
                icon: 'bi bi-bar-chart',
                dataTestSelector: 'shared-side-menu_client-categories_usage-records',
                description: 'Usage records',
                page: ClientTabRootPages.USAGE_RECORDS,
            },
            {
                children: [],
                icon: 'bi bi-chat-left-dots',
                description: 'SMS',
                page: ClientTabRootPages.SMS,
            },
            {
                children: [
                    {
                        icon: 'bi bi-receipt',
                        description: 'Invoice list',
                        page: ClientTabChildPages.INVOICE_LIST,
                    },
                    {
                        icon: 'bi bi-calendar2-month',
                        description: 'Monthly statement',
                        page: ClientTabChildPages.MONTHLY_STATEMENT,
                    },
                ],
                icon: 'bi bi-receipt',
                description: 'Invoices',
                dataTestSelector: 'shared-side-menu_client-categories_invoices',
                page: ClientTabRootPages.ORDERS,
            },
            {
                children: [],
                icon: 'bi bi-file-excel',
                description: 'Reporting',
                page: ClientTabRootPages.REPORTING,
            },
            {
                children: [],
                icon: 'bi bi-people',
                description: 'Users',
                page: ClientTabRootPages.USERS,
            },
            {
                children: [],
                icon: 'bi bi-bell',
                description: 'System Alerts',
                page: ClientTabRootPages.SYSTEM_ALERTS,
            },
            {
                children: [],
                icon: 'bi bi-app-indicator',
                description: 'Notifications',
                page: ClientTabRootPages.NOTIFICATIONS,
            },
        ];

        {
            const _index = _filtered.findIndex(
                (f) => f.page === ClientTabRootPages.ACCOUNT_SETTINGS
            );
            if (_index > -1) {
                if (
                    (SystemUserTypes.ADMIN == session_info.user_details.user_category &&
                        !this.hasPermission(
                            session_info.user_details.user_category + '.CLIENTMANAGEMENT.R'
                        )) ||
                    (SystemUserTypes.ADMIN != session_info.user_details.user_category &&
                        !this.hasPermission(
                            session_info.user_details.user_category + '.CLIENTMANAGEMENT.RS'
                        ))
                ) {
                    _filtered[_index].children = _filtered[_index].children.filter(
                        (p) => p.page !== ClientTabChildPages.PROFILE
                    );
                }
                if (
                    !(
                        client_details.payment.allow &&
                        client_details.payment.methods.wallet &&
                        this.hasPermission(
                            session_info.user_details.user_category +
                                '.CLIENTMANAGEMENT.READINVOICES'
                        ) &&
                        (SystemUserTypes.ADMIN == session_info.user_details.user_category ||
                            SystemUserTypes.CLIENT == client_details.user_type)
                    )
                ) {
                    _filtered[_index].children = _filtered[_index].children.filter(
                        (p) => p.page !== ClientTabChildPages.WALLET
                    );
                }
                if (
                    !(
                        client_details.payment.allow &&
                        client_details.payment.methods.creditcard &&
                        (SystemUserTypes.ADMIN == session_info.user_details.user_category ||
                            SystemUserTypes.CLIENT == client_details.user_type)
                    )
                ) {
                    _filtered[_index].children = _filtered[_index].children.filter(
                        (p) => p.page !== ClientTabChildPages.PAYMENT
                    );
                }
                if (client_details.id === session_info.user_details.id) {
                    _filtered[_index].children = _filtered[_index].children.filter(
                        (p) => p.page !== ClientTabChildPages.PRIVILEGES
                    );
                }
                if (
                    !this.hasPermission(
                        session_info.user_details.user_category + '.CLIENTMANAGEMENT.APIACCESS'
                    ) &&
                    !this.hasPermission(
                        client_details.user_category + '.CLIENTMANAGEMENT.APIACCESS'
                    )
                ) {
                    _filtered[_index].children = _filtered[_index].children.filter(
                        (p) => p.page !== ClientTabChildPages.API_KEYS
                    );
                }
                if (SystemUserTypes.ADMIN !== session_info.user_details.user_category) {
                    _filtered[_index].children = _filtered[_index].children.filter(
                        (p) => p.page !== ClientTabChildPages.DOCUMENTS
                    );
                }
                if (
                    SystemUserTypes.ADMIN !== session_info.user_details.user_category &&
                    SystemUserTypes.CLIENT !== session_info.user_details.user_type
                ) {
                    _filtered[_index].children = _filtered[_index].children.filter(
                        (p) => p.page !== ClientTabChildPages.SETTINGS
                    );
                }
                if (!_filtered[_index].children.length) {
                    _filtered = _filtered.filter(
                        (p) => p.page !== ClientTabRootPages.ACCOUNT_SETTINGS
                    );
                }
            }
        }

        {
            const _index = _filtered.findIndex((f) => f.page === ClientTabRootPages.INVENTORY);
            if (
                !this.hasPermission(
                    session_info.user_details.user_category + '.CLIENTMANAGEMENT.REQUESTINVENTORY'
                ) &&
                !this.hasPermission(
                    client_details.user_category + '.CLIENTMANAGEMENT.REQUESTINVENTORY'
                )
            ) {
                _filtered[_index].children = _filtered[_index].children.filter(
                    (p) => p.page !== ClientTabChildPages.INVENTORY_REQUEST
                );
            }
            if (
                !_filtered[_index].children.length ||
                !this.hasPermission(
                    session_info.user_details.user_category + '.CLIENTMANAGEMENT.READINVENTORY'
                )
            ) {
                _filtered = _filtered.filter((p) => p.page !== ClientTabRootPages.INVENTORY);
            }
        }

        if (
            !this.hasPermission(
                session_info.user_details.user_category + '.CLIENTMANAGEMENT.READBG'
            ) &&
            !this.hasPermission(client_details.user_category + '.CLIENTMANAGEMENT.READBG')
        ) {
            _filtered = _filtered.filter((p) => p.page !== ClientTabRootPages.SUBSCRIPTIONS);
        }

        {
            const _index = _filtered.findIndex((f) => f.page === ClientTabRootPages.ORDERS);
            if (client_details.payment.billing_type !== 'postpaid') {
                _filtered[_index].children = _filtered[_index].children.filter(
                    (p) => p.page !== ClientTabChildPages.MONTHLY_STATEMENT
                );
            }
            if (
                !this.hasPermission(
                    session_info.user_details.user_category + '.CLIENTMANAGEMENT.READINVOICES'
                ) &&
                !this.hasPermission(client_details.user_category + '.CLIENTMANAGEMENT.READINVOICES')
            ) {
                _filtered[_index].children = _filtered[_index].children.filter(
                    (p) => p.page !== ClientTabChildPages.INVOICE_LIST
                );
                _filtered[_index].children = _filtered[_index].children.filter(
                    (p) => p.page !== ClientTabChildPages.MONTHLY_STATEMENT
                );
            }
            if (!_filtered[_index].children.length) {
                _filtered = _filtered.filter((p) => p.page !== ClientTabRootPages.ORDERS);
            }
        }

        if (
            !this.hasPermission(
                session_info.user_details.user_category + '.CLIENTMANAGEMENT.MANAGEALERTS'
            )
        ) {
            _filtered = _filtered.filter((p) => p.page !== ClientTabRootPages.SYSTEM_ALERTS);
        }

        if (!this.hasPermission(session_info.user_details.user_category + '.CLIENTMANAGEMENT.R')) {
            _filtered = _filtered.filter((p) => p.page !== ClientTabRootPages.USERS);
        }

        if (
            !this.hasPermission(
                session_info.user_details.user_category + '.CLIENTMANAGEMENT.REPORTING'
            )
        ) {
            _filtered = _filtered.filter((p) => p.page !== ClientTabRootPages.REPORTING);
        }

        if (
            !this.hasPermission(
                session_info.user_details.user_category + '.CLIENTMANAGEMENT.MANAGESMSREQUEST'
            )
        ) {
            _filtered = _filtered.filter((p) => p.page !== ClientTabRootPages.SMS);
        }

        if (
            !this.hasPermission(
                session_info.user_details.user_category + '.CLIENTMANAGEMENT.MANAGENOTIFICATION'
            )
        ) {
            _filtered = _filtered.filter((p) => p.page !== ClientTabRootPages.NOTIFICATIONS);
        }

        this.store
            .select(AppSelectors.selectSessionInfoResellerPrivileges)
            .pipe(
                map((resellerPrivileges) => {
                    return resellerPrivileges?.includes('reseller.FEATURES.SMS_FEATURES');
                })
            )
            .pipe(first())
            .subscribe((v) => {
                if (!v) {
                    _filtered = _filtered.filter((p) => p.page !== ClientTabRootPages.SMS);
                }
            });

        if (!_filtered.length) {
            if (
                !this.hasPermission(session_info.user_details.user_category + '.DASHBOARD.R') &&
                !this.hasPermission(session_info.user_details.user_category + '.SEARCHINVENTORY.R')
            ) {
                this.functionService.showAlertMessage(
                    'Permission to all pages has been disabled, please contact your admin.'
                );
                this.logMeOut();
            }
            return;
        }

        if (
            !session_info.last_routes.tabs.client.root ||
            !_filtered.find((f) => f.page == session_info.last_routes.tabs.client.root)
        ) {
            session_info.last_routes.tabs.client.root = _filtered[0].page;
        }

        this.setSessionData(session_info, false);
        return {
            tabs: _filtered,
            page: session_info.last_routes.tabs.client.root,
        };
    }

    async getStatementSummary(
        session_info: SessionInfo,
        reseller_details: ResellerInterface
    ): Promise<STATEMENT_SUMMARY> {
        if (!reseller_details) return;
        if (!reseller_details.account_statement_summary) {
            try {
                const url =
                    session_info.user_details.user_category === SystemUserTypes.CRM
                        ? `${this.config.apiEndpoints.crm.resellers}/${reseller_details.id}/account-management/soa-summary`
                        : `${this.config.apiEndpoints.admin.manage}/account-management/soa-summary`;
                let response: any = await this.httpClient.get(url).toPromise();
                reseller_details.account_statement_summary = response.data;
            } catch (error) {}
        }
        return reseller_details.account_statement_summary;
    }
}
