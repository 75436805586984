import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from './modules/core/config/config';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EncodeHttpParamsInterceptor } from './interceptor/encode-http-params.interceptor';
import { currenciesReducer } from './modules/shared/components/currency-select/currency-select.store';
import { CurrencySelectEffects } from './modules/shared/components/currency-select/currency-select.effects';
import {
    CountrySelectEffects,
    countrySelectStore,
} from './modules/shared/components/country-select/country-select.store';
import { InlineSVGModule } from 'ng-inline-svg-2';
import {
    MccCodesSelectEffects,
    mccCodesSelectStore,
} from './modules/shared/components/mcc-select/mcc-select.store';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-center',
            toastClass: 'ngx-toastr mt-4',
            tapToDismiss: false,
            messageClass: 'small',
        }),
        InlineSVGModule.forRoot(),
        RecaptchaV3Module,
        BrowserAnimationsModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([AppEffects]),
        StoreRouterConnectingModule.forRoot(),
        StoreModule.forFeature('currencySelect', currenciesReducer),
        EffectsModule.forFeature([CurrencySelectEffects]),
        StoreModule.forFeature(countrySelectStore.name, countrySelectStore.reducer),
        EffectsModule.forFeature([CountrySelectEffects]),
        StoreModule.forFeature(mccCodesSelectStore.name, mccCodesSelectStore.reducer),
        EffectsModule.forFeature([MccCodesSelectEffects]),
    ],
    providers: [
        { provide: APP_CONFIG, useValue: AppConfig },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.google_keys.recaptcha,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EncodeHttpParamsInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
