import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';
import { CoreService } from './modules/core/services/core.service';
import * as AppActions from './store/actions/app.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        public readonly coreService: CoreService,
        private store: Store
    ) {
        this.coreService.session_dataObservable
            .pipe(
                distinctUntilChanged((prev, curr) => {
                    return prev?.token === curr?.token;
                })
            )
            .subscribe((si) => {
                const deepCopy = JSON.parse(JSON.stringify(si));
                this.store.dispatch(AppActions.startUp({ data: deepCopy }));
            });

        if (this.coreService.isLoggedIn()) {
            this.coreService.refreshSession();
        }
    }
}
