export interface Pagination {
    currentPage: number;
    perPage: number;
    total: number;
    totalPages: number;
}

export interface Paginated<T> extends Pagination {
    data: T[];
}

export interface BillingModel {
    id: 'dtonsim' | 'dtonpool' | 'corporate';
    description: string;
    data_sharing: boolean;
    fixed_multiplier: number;
    default_on_request: boolean;
}

export enum ChipType {
    sim = 'sim',
    esim = 'esim',
    microchip = 'microchip',
}

export interface BGSKU {
    sku: number;
    chip_type: ChipType;
}

export interface BGStock extends BGSKU {
    iccid?: string;
    billed: boolean;
    auto_topup_product_id: string;
    auto_topup_settings: AutoTopupSettings;
}

export interface AutoTopupSettings {
    enabled: boolean;
    quantity: number;
    threshold: number;
    threshold_in_percentage: boolean;
    count_limit?: number;
    inform_reseller_on_limit_reached?: boolean;
}

export interface COUNTRY {
    timezones: string[];
    latlng: number[];
    name: string;
    country_code: string;
    capital: string;
}

export enum Statuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum ClientBillingTypes {
    PREPAID = 'prepaid',
    POSTPAID = 'postpaid',
}

export interface SkuConnectionDetails {
    sku?: number;
    bucket: number;
    sequence: string;
    MessageId: string;
    creation_date: string;
}

export interface STRIPE_SUPPORTED_CURRENCY {
    currency: string;
    unit_multiplier: number;
    min_charge: number;
}
