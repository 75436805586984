import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_HOST } from 'src/environments/environment';
import { SystemUserTypes } from '@app/modules/shared/models/enums/system-user-types';

export interface ProxyingInfo {
    id: string;
    name: string;
    roles: string[];
    user_category:
        | SystemUserTypes.ADMIN
        | SystemUserTypes.CLIENT
        | SystemUserTypes.CRM;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private httpClient: HttpClient) {}

    getProxyingInfo() {
        return this.httpClient.post<ProxyingInfo>(`${API_HOST}/auth/proxying-info`, {});
    }
}
