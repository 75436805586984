import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resources } from '@shared/models/resources';
import { PresignedPolicy, ModuleNameDownload, ModuleNameUpload } from '@core/types/common';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { API_HOST } from 'src/environments/environment';
import { STRIPE_SUPPORTED_CURRENCY } from '@app/types/general';

export interface Branding {
    logoUrl: string;
    logoWidth: number;
    colors: Record<string, string>;
    hideSignup: boolean;
    title: string;
    favicon: string;
}

@Injectable({
    providedIn: 'root',
})
export class ResourcesService {
    constructor(private httpClient: HttpClient) {}

    getResources() {
        return this.httpClient.get<Resources>(`${API_HOST}/resources/general`);
    }

    getPolicy(moduleName: ModuleNameUpload): Observable<PresignedPolicy> {
        return this.httpClient
            .get<{ policy: PresignedPolicy }>(`${API_HOST}/resources/${moduleName}`)
            .pipe(map((v) => v.policy));
    }

    getFileUrl(
        moduleName: ModuleNameUpload | ModuleNameDownload,
        id: string,
        fileName: string
    ): Observable<string> {
        return this.httpClient
            .get<{ url: string }>(`${API_HOST}/resources/${moduleName}`, {
                params: { id, file_name: fileName },
            })
            .pipe(map((v) => v.url));
    }

    getPortalLogo() {
        return this.httpClient
            .get<{
                data: string;
                logoWidth: number;
                colors?: Record<string, string>;
                hideSignup?: boolean;
                title: string;
                favicon?: string;
            }>(`${API_HOST}/resources/portal-logo`, {
                params: { url: window.location.origin.split('//').pop() },
                headers: { 'skip-alert': 'true' },
            })
            .pipe(
                map((r) => {
                    return {
                        logoUrl: r.data,
                        logoWidth: r.logoWidth,
                        colors: r.colors,
                        hideSignup: r.hideSignup,
                        title: r.title,
                        favicon: r.favicon,
                    };
                })
            );
    }

    getCoverageGroups() {
        return this.httpClient.get<{ data: any }>(`${API_HOST}/crm/resources/coverage-groups`);
    }

    getResellers() {
        return this.httpClient.get<{ data: any }>(`${API_HOST}/crm/resources/resellers`);
    }

    getResellerClientsByCrm(
        resellerId: string,
        newParams: ResellerClientsParams = {
            page: 1,
            userCategory: 'client',
            limit: 1000,
            sort: 'desc',
        }
    ) {
        let params = new HttpParams();
        params = params.append('page', newParams.page);
        params = params.append('user_category', newParams.userCategory);
        params = params.append('limit', newParams.limit);
        params = params.append('sort', newParams.sort);
        params = params.append('search', newParams.search);

        return this.httpClient.get<{ data: any }>(
            `${API_HOST}/users/list?reseller_id=${resellerId}`,
            { params }
        );
    }

    getCurrencies() {
        return this.httpClient.get<STRIPE_SUPPORTED_CURRENCY[]>(`${API_HOST}/resources/currencies`);
    }

    getSupportedCountries() {
        return this.httpClient.get<any>(`${API_HOST}/resources/countries`);
    }

    getMccCodes() {
        return this.httpClient.get<Record<string, string>>(`${API_HOST}/resources/mcc-codes`).pipe(
            map((v) => {
                return Object.keys(v).map((key) => {
                    return {
                        code: key,
                        country: v[key],
                    };
                });
            })
        );
    }
}

export interface ResellerClientsParams {
    page: number;
    userCategory: string;
    limit?: number;
    sort?: string;
    search?: string;
}
