import { Injectable } from '@angular/core';
import { ResourcesService } from '@app/modules/core/services/resources.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as AppActions from '../actions/app.actions';
import { AuthService } from '@app/modules/core/services/auth.service';

@Injectable()
export class AppEffects {
    constructor(
        private actions$: Actions,
        private resourcesService: ResourcesService,
        private authService: AuthService
    ) {}

    startUp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.startUp),
            mergeMap(({ data }) => {
                const actions: Action[] = [AppActions.loadSessionInfoSuccess({ data })];
                const loggedInUserStartUpAction = [];
                if (data?.token) {
                    loggedInUserStartUpAction.push(AppActions.loadResources());
                }
                return [...actions, ...loggedInUserStartUpAction];
            })
        )
    );

    sessionInfoSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadSessionInfoSuccess),
            switchMap(({ data }) => {
                if (!data?.token) {
                    return [AppActions.loadBranding()];
                }

                const reseller = data?.reseller_details;
                const portalLogo = reseller?.settings?.logos?.portal;

                return [
                    AppActions.loadBrandingSuccess({
                        logoUrl: portalLogo?.enable && portalLogo?.url,
                        favicon: portalLogo?.enable && portalLogo?.favicon_url,
                        logoWidth: (portalLogo?.enable && portalLogo?.width) || 0,
                        colors: reseller?.settings?.colors,
                        hideSignup: reseller?.settings?.hide_signup,
                        title: reseller?.company ? `${reseller?.company} | Solutions` : null,
                    }),
                ];
            })
        );
    });

    loadBranding$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadBranding),
            switchMap(() => {
                return this.resourcesService.getPortalLogo().pipe(
                    map((branding) => AppActions.loadBrandingSuccess(branding)),
                    catchError((error) => of(AppActions.loadBrandingFailure({ error })))
                );
            })
        );
    });

    loadResources$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadResources),
            switchMap(() => {
                return this.resourcesService.getResources().pipe(
                    map((resources) => AppActions.loadResourcesSuccess({ resources })),
                    catchError((error) => of(AppActions.loadSessionInfoFailure({ error })))
                );
            })
        );
    });

    loadProxyingUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadProxyingUser),
            switchMap(() => {
                return this.authService.getProxyingInfo().pipe(
                    map((proxyingUser) => AppActions.loadProxyingUserSuccess({ proxyingUser })),
                    catchError((error) => of(AppActions.loadProxyingUserFailure({ error })))
                );
            })
        );
    });
}
