import { SystemUserTypes } from '@app/modules/shared/models/enums/system-user-types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApp from '../reducers/app.reducer';

export const selectAppState = createFeatureSelector<fromApp.State>(fromApp.appKey);

export const selectSessionInfo = createSelector(selectAppState, (state) => state.sessionInfo);

export const selectResources = createSelector(selectAppState, (state) => state.resources);
export const selectBranding = createSelector(selectAppState, (state) => state.branding);
export const selectProxyingUser = createSelector(selectAppState, (state) => state.proxyingUser);

export const selectUserInfo = createSelector(
    selectSessionInfo,
    (sessionInfo) => sessionInfo.user_details
);

export const selectUserRoles = createSelector(selectUserInfo, (userDetails) => userDetails.roles);

export const selectUserInventory = createSelector(
    selectUserInfo,
    (userDetails) => userDetails.inventory
);

export const isSuperAdmin = createSelector(
    selectUserRoles,
    (roles) => Array.isArray(roles) && roles.includes('*')
);

export const selectSessionInfoResources = createSelector(
    selectSessionInfo,
    (sessionInfo) => sessionInfo.resources
);

export const selectSessionInfoResourcesBillingModels = createSelector(
    selectSessionInfoResources,
    (resources) => resources?.billing_models
);

export const selectSessionInfoCache = createSelector(
    selectSessionInfo,
    (sessionInfo) => sessionInfo?.cache
);

export const selectSessionInfoCachedCoverageGroups = createSelector(
    selectSessionInfoCache,
    (cache) => cache?.coverage_groups
);

export const selectSessionInfoCachedClients = createSelector(
    selectSessionInfoCache,
    (cache) => cache?.client_list
);

export const selectSessionInfoResourcesAppRoles = createSelector(
    selectSessionInfo,
    (sessionInfo) => sessionInfo.resources?.app_roles
);

export const selectSessionInfoResellerDetails = createSelector(
    selectSessionInfo,
    (sessionInfo) => sessionInfo?.reseller_details
);

export const selectSessionInfoResellerPrivileges = createSelector(
    selectSessionInfo,
    (sessionInfo) => sessionInfo?.reseller_details?.privileges
);

export const selectUserId = createSelector(selectUserInfo, (userInfo) => userInfo?.id);

export const selectUserCategory = createSelector(
    selectUserInfo,
    (userInfo) => userInfo?.user_category
);

export const isAdmin = createSelector(
    selectUserCategory,
    (userCategory) => userCategory === SystemUserTypes.ADMIN
);

export const selectResourcesResellerPrivileges = createSelector(
    selectResources,
    (resources) => resources.reseller_privileges
);

export const selectProxyingUserCategory = createSelector(
    selectProxyingUser,
    (proxyingUser) => proxyingUser?.user_category
);

/**
 * Big features, blocked per entire reseller/client account
 * @param privilegeToCheck
 * @returns
 */
export const hasPrivilege = (privilegeToCheck: string) => {
    return createSelector(
        selectSessionInfoResellerPrivileges,
        selectUserInfo,
        (privileges, userInfo) => {
            if (userInfo.user_category === SystemUserTypes.CRM) {
                return true;
            }
            if (!Array.isArray(privileges) || privileges.length === 0) {
                return false;
            }
            return (
                privileges.some((privilege) => privilege === privilegeToCheck) ||
                privileges.includes('*') ||
                !privilegeToCheck
            );
        }
    );
};

/**
 * Specific actions, blocked per single user
 * @param privilegeToCheck
 * @returns
 */
export const hasRole = (roleToCheck: string | string[]) => {
    return createSelector(selectUserRoles, (roles) => {
        if (!Array.isArray(roles) || roles.length === 0) {
            return false;
        }

        if (typeof roleToCheck === 'string') {
            roleToCheck = [roleToCheck];
        }

        for (const r of roleToCheck) {
            if (
                roles.some((role) => role === r) ||
                (roles.includes('*') && !r.startsWith('crm.'))
            ) {
                return true;
            }
        }

        return false;
    });
};
